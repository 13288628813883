export const countryIdNumbersFormInputsMain = {
    IN: [
        {
            id: 'PANCode',
            value: '',
            type: 'PANCode',
            label: 'PAN Number*',
            validationRequired: true,
        },
    ],
    US:
        [
            {
                id: 'socialSecurityNumber',
                value: '',
                type: 'socialSecurityNumber',
                label: 'Social Security Number*',
                validationRequired: true,
            },
        ],
    AU: [
        {
            id: 'drivingLicenseNumberAU',
            value: '',
            type: 'drivingLicenseNumberAU',
            label: 'Driving License Number*',
            validationRequired: true,
        },

    ]

};

export const countryIdNumbersFormInputsAlternative = {
    IN: [
        {
            id: 'aadhaarCardNumber',
            value: '',
            type: 'aadhaarCardNumber',
            label: 'Aadhaar Card Number',
            validationRequired: true
        },
        {
            id: 'drivingLicenseNumberIN',
            value: '',
            type: 'drivingLicenseNumberIN',
            label: 'Driving License Number',
            validationRequired: true,
        },
        {
            id: 'NREGACardNumber',
            value: '',
            type: 'NREGACardNumber',
            label: 'NREGA Card Number',
            validationRequired: true,
        },
    ],
    US: [
    ],
    AU: [
    ]
};