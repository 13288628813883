// products.js - Vuex module for managing product data
import { addBreadcrumb } from '@sentry/vue';
import { setPersistence } from "firebase/auth";
import { intercomLogout, updateIntercomUser } from "../controllers/intercom";
import { checkClientToken } from "../controllers/user/auth";
import { fbAuth } from "../main";
import { LocalStorage } from "../utils/localStorage";
import { browserSessionPersistence, signInWithCustomToken } from "@firebase/auth";
import { setAnalyticsUserId } from "../controllers/analytics/firebase";
import store from '../store';

const state = {
    userId: null,
    token: null,
    tokenExpiration: null,
    userData: null,

};

const mutations = {

    SET_USER(state, payload) {

        addBreadcrumb({
            category: 'auth',
            message: 'store - SET_USER',
            data: {
                payload
            },
            level: 'info'
        });

        state.token = payload.token;
        state.userId = payload.userId;
        state.tokenExpiration = payload.tokenExpiration;

        // state.didAutoLogout = false;
    },
    SET_USER_DATA(state, payload) {
        state.userData = payload;
    },

};

const actions = {
    logout({ commit }) {

        LocalStorage.remove(['token', 'tokenExpiration', 'verificationId', 'uid'])
        // localStorage.removeItem('applicationId');

        fbAuth.signOut();
        intercomLogout();

        commit('SET_USER', {
            token: null,
            userId: null,
            tokenExpiration: null
        });
    },
    async signIn({ commit }, payload) {
        try {

            addBreadcrumb({
                category: 'auth',
                message: 'store - auth/signIn()...',
                level: 'info'
            });

            await setPersistence(fbAuth, browserSessionPersistence);
            const userCredential = await signInWithCustomToken(fbAuth, payload.token);

            addBreadcrumb({
                category: 'auth',
                message: 'User is logged in!',
                level: 'info'
            });

            const expirationTime = userCredential.user.stsTokenManager.expirationTime;

            commit('SET_USER', {
                token: payload.token,
                userId: userCredential.user.uid,
                tokenExpiration: expirationTime
            });

            // expires in
            LocalStorage.set([{ token: payload.token, tokenExpiration: expirationTime }])
            // localStorage.setItem('token', payload.token);
            // localStorage.setItem('tokenExpiration', expirationTime);
            await updateIntercomUser({ userId: userCredential.user.uid });

            setAnalyticsUserId(userCredential.user.uid);

            const userIdLocal = localStorage.getItem('uid');

            addBreadcrumb({
                category: 'auth',
                message: `uid in localStorage is ${userIdLocal}`,
                level: 'info'
            });

            if (userIdLocal && (userCredential.user.uid !== userIdLocal)) {
                // localStorage.removeItem('applicationId');
                addBreadcrumb({
                    category: 'auth',
                    message: `uid in localStorage is different!`,
                    level: 'warning'
                });

                LocalStorage.remove('applicationId')
            }

            localStorage.setItem('uid', userCredential.user.uid);
            return {
                status: true,
                data: userCredential.user
            };

        } catch (error) {
            const errorMessage = error.message;
            localStorage.removeItem('token');
            localStorage.removeItem('tokenExpiration');
            return {
                status: false,
                data: errorMessage
            };
        }
    },

    async trySignIn({ dispatch }) {

        addBreadcrumb({
            category: 'auth',
            message: 'store - auth/trySignIn()...',
            level: 'info'
        });
        // IF just logged in -  set to store 
        const token = localStorage.getItem('token');

        const verificationId = LocalStorage.get('verificationId');
        const tokenExpiration = LocalStorage.get('tokenExpiration');

        if (token && verificationId && tokenExpiration) {
            addBreadcrumb({
                category: 'auth',
                message: 'token, verificationId and tokenExpiration are passed',
                level: 'info'
            });

            const checkToken = checkClientToken(tokenExpiration);

            if (checkToken) {
                addBreadcrumb({
                    category: 'auth',
                    message: 'checkToken() - token is not expired',
                    level: 'info'
                });

                const resp = await dispatch('signIn', { token });
                return resp;
            } else {

                addBreadcrumb({
                    category: 'auth',
                    message: 'checkToken() - token is expired',
                    level: 'info'
                });

                LocalStorage.remove(['token', 'tokenExpiration']);

                return {
                    status: false
                };
            }

        } else {

            store.dispatch('error/throwNewError', {
                module: 'store - auth/trySignIn()',
                errorType: 'authError',
                comment: 'An error in trySignIn function. Some of the required params are missing',
                context: {
                    dataPassed: {
                        token: token ?? null,
                        verificationId: verificationId ?? null,
                        tokenExpiration: tokenExpiration ?? null,
                    },
                }
            });

            dispatch('logout');

            return {
                status: false
            };
        }
    }

};

const getters = {
    token: state => state.token,
    userId: state => state.userId,
    isAuthenticated: (state) => {

        if (state.userId && state.token && state.tokenExpiration) {

            const checkToken = checkClientToken(state.tokenExpiration);
            return checkToken
        }
        return false
    },
    userData: (state) => state.userData,
};

export default {
    namespaced: true, // Namespaced module
    state,
    mutations,
    actions,
    getters,
};
