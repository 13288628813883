export const countryIdDetailsFormInputsMain = {
    IN: [
        {
            id: 'PANIND',
            value: '',
            type: 'PANCode',
            label: 'PAN Number*',
            validationRequired: true,
        },
    ],
    US:
        [
            {
                id: 'SSNUSA',
                value: '',
                type: 'text',
                label: 'Social Security Number*',
                validationRequired: true,
            },
        ],
    AU: [
        {
            id: 'DLNAUS',
            value: '',
            type: 'text',
            label: 'Driving License Number*',
            validationRequired: true,
        },

    ]

};

export const countryIdDetailsFormInputsAlternative = {
    IN: [
        {
            id: 'AadharIND',
            value: '',
            type: 'AadharIND',
            label: 'Aadhaar Card Number',
            validationRequired: true
        },
        {
            id: 'DLNIND',
            value: '',
            type: 'DLNIND',
            label: 'Driving License Number',
            validationRequired: true,
        },
        {
            id: 'NREGAIND',
            value: '',
            type: 'NREGAIND',
            label: 'NREGA Card Number',
            validationRequired: true,
        },
    ],
    US: [
    ],
    AU: [
    ]
};