<template>
  <div class="desktop-only" :style="computedStyle">
    <BaseImage class="pillar-logo" image="pillar-logo" width="100" />
    <BaseImage :image="desktopImage" :width="imageWidth" class="p-t-5" />
  </div>
</template>

<script>
import BaseImage from '../assets/BaseImage.vue';
export default {
  components: { BaseImage },
  props: {
    desktopBGHidden: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    desktopImage() {
      return (
        this.$store.getters['navigation/customDesktopBGImage'] ??
        'application-desktop'
      );
    },
    computedStyle() {
      return {
        backgroundColor:
          this.desktopImage === 'card-desktop' ? '#242424' : '#e8fdf5',
      };
    },
    logoStyle() {
      return {};
    },
    imageWidth() {
      return this.desktopImage === 'card-desktop' ? '70%' : '380px';
    },
  },
};
</script>

<style lang="scss" scoped>
.desktop-only {
  display: none;

  @media (min-width: 1024px) {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background-color: #e8fdf5;
    flex: 1;
    width: 50%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .pillar-logo {
    position: absolute;
    top: 18px;
    left: 40px;
  }
}
</style>
