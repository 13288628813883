import { sendRequest } from "../config/axios/helper";

export const applicationUrls = {
    APPLICATION_RECORD: {
        url: '/application/read',
        type: 'post',
        reqParams: ['applicationId']
    },
    CREATE_APPLICATION: {
        url: '/application/create',
        type: 'post',
        reqParams: []
    },
    UPDATE_APPLICATION: {
        url: '/application/update',
        type: 'post',
        reqParams: ['applicationId', 'data']
    },
    UPDATE_APPLICATION_RECORD: {
        url: '/application/update-record',
        type: 'post',
        reqParams: ['applicationId', 'userId', 'data']
    },
    CHANGE_PRODUCT_TYPE: {
        url: '/application/change-card-product-type',
        type: 'post',
        reqParams: ['userId', 'applicationId', 'cardProductType']
    },

    CREATE_MONITOR_APPLICATION: {
        url: '/application/pillarMonitor',
        type: 'post',
        reqParams: ['userId', 'applicationId']
    },
    QUEUE_COUNTER: {
        url: '/application/queue-counter/',
        type: 'get',
        reqParams: ['userId']
    },
    SKIP_THE_QUEUE: {
        url: '/application/skip-the-queue',
        type: 'post',
        reqParams: ['userId']

    }

};

export const getApplicationRecord = async params => {
    return await sendRequest(applicationUrls.APPLICATION_RECORD, params);
};

export const createApplication = async params => {
    return await sendRequest(applicationUrls.CREATE_APPLICATION, params);
};

export const updateApplication = async params => {
    return await sendRequest(applicationUrls.UPDATE_APPLICATION, params);
};

export const updateApplicationRecord = async params => {
    return await sendRequest(applicationUrls.UPDATE_APPLICATION_RECORD, params);
};

export const changeProductType = async params => {
    return await sendRequest(applicationUrls.CHANGE_PRODUCT_TYPE, params);
};

export const createMonitorApplication = async params => {
    return await sendRequest(applicationUrls.CREATE_MONITOR_APPLICATION, params);
};

export const getQueueCounter = async params => {
    const reqObj = applicationUrls.QUEUE_COUNTER;
    reqObj.url = applicationUrls.QUEUE_COUNTER.url + params?.userId;
    return await sendRequest(reqObj, params);
};

export const skipTheQueue = async params => {
    return await sendRequest(applicationUrls.SKIP_THE_QUEUE, params);
};
