<template>
  <div class="app-bar-wrap">
    <div v-if="backButtonVisible" class="back-button">
      <BaseButtonIcon v-if="!startScreen" icon="arrow-left" @clicked="back" />
      <BaseButtonIcon v-else icon="close" @clicked="backToApp" />
    </div>
    <div v-if="progressBarVisible" class="app-pr-bar">
      <ProgressBar />
    </div>
  </div>
</template>

<script>
import BaseButtonIcon from '../buttons/BaseButtonIcon.vue';
import ProgressBar from '../progressBar/ProgressBar.vue';

export default {
  components: { ProgressBar, BaseButtonIcon },

  computed: {
    startScreen() {
      return !this.$store.getters['navigation/activeScreen'];
    },

    progressBarVisible() {
      return this.$store.state.navigation.progressBarVisible;
    },
    backButtonVisible() {
      return this.$store.getters['navigation/backButtonVisible'];
    },
  },

  methods: {
    backToApp() {
      window.App.postMessage('Back');
    },
    back() {
      this.$store.commit('navigation/UPDATE_ANIMATION_MODE', 'reverse');
      this.$store.dispatch('navigation/backToPreviousScreen');
    },
  },
};
</script>

<style lang="scss" scoped>
.app-bar-wrap {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: center;
  position: relative;
}

.app-pr-bar {
  height: 100%;
  z-index: 1000;
}
.back-button {
  display: flex;
  height: 100%;
  position: absolute;
  left: -10px !important;
  z-index: 3;
}
</style>
