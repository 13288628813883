// useDeviceType.js
import { ref, onMounted, onUnmounted } from 'vue';

export function useDeviceType(breakpoint = 1024) {
    const isDesktop = ref(window.innerWidth > breakpoint);

    const updateDeviceType = () => {
        isDesktop.value = window.innerWidth > breakpoint;
    };

    onMounted(() => {
        window.addEventListener('resize', updateDeviceType);
    });

    onUnmounted(() => {
        window.removeEventListener('resize', updateDeviceType);
    });

    return {
        isDesktop
    };
}
