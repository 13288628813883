
import { fbAuth } from '../../main';
import { http } from '../../config/axios';

import {
  // browserSessionPersistence,
  signOut,
  // setPersistence,
  // signInWithCustomToken,
} from 'firebase/auth';
// import moment from 'moment';
import { errorResponse, successResponse } from '../services/response';
import store from '../../store';
import router from '../../router';
import { intercomLogout } from '../intercom';


export const copyApplicationToUser = async (userId, phoneNumber) => {

  const url = '/user/create';

  try {
    const response = await http.post(url, { userAuthId: userId, phone: phoneNumber });
    return response;
  } catch (err) {
    console.log(err);
  }

};

export const getCurrentUser = () => {
  try {
    const user = fbAuth.currentUser;
    if (!user.uid) {
      return false
    }
    return user
  } catch (error) {
    return false
  }

};

export const getUserRecord = async (userId) => {
  const url = '/user/read';

  try {
    const response = await http.post(url, { userId });
    return successResponse(response.data.data)

  } catch (err) {
    const errResp = errorResponse(err);
    return errResp
  }
};

export const checkClientToken = (expiresAt) => {

  if (+expiresAt - Date.now() > 10) {
    return true
  } else {
    return false
  }


};

export const phoneNumberLookup = async (phoneNumber) => {

  const url = '/auth/phone/lookup';

  try {
    const response = await http.post(url, { phoneNo: phoneNumber });
    const data = response.data.data;

    return successResponse(data);
  } catch (err) {
    const errResp = errorResponse(err);
    store.dispatch('error/setErrorMessage', errResp.data);
    store.dispatch('error/showErrorScreen');
    return errResp
  }
};

export const initiateVerification = async (userId) => {
  const url = '/auth/phoneVerification/initiate';

  try {
    const response = await http.post(url, { userId });
    const data = response.data.data;

    return successResponse(data);
  } catch (err) {
    const errResp = errorResponse(err);
    store.dispatch('error/setErrorMessage', errResp.data);
    store.dispatch('error/showErrorScreen');
    return errResp
  }
};

export const submitOTP = async (verificationId, otp, applicationId) => {
  const url = '/auth/phoneVerification/submit';
  const payload = {
    phoneVerificationId: verificationId,
    verificationCode: otp,
    applicationId: applicationId,
  };

  try {
    const response = await http.post(url, payload);
    const data = response.data.data

    return successResponse(data);
  } catch (err) {

    const errResponse = errorResponse(err);
    store.dispatch('navigation/showErrorSnackbar', errResponse.data);
    return errorResponse(err);
  }
};



export const submitPin = async (verificationId, pin, applicationId) => {


  const url = '/auth/token/generate';

  const payload = {
    phoneVerificationId: verificationId,
    pin: pin,
    applicationId: applicationId,
  };

  try {
    const response = await http.post(url, payload);

    const data = response.data.data;

    if (data.match) {
      const token = data.customToken;
      await store.dispatch('auth/signIn', { token });
    }
    return successResponse(data);

  } catch (err) {

    const errResponse = errorResponse(err);

    if (errResponse.statusCode == 403) {
      store.dispatch('error/setErrorMessage', errResponse.data)
      router.push('/paused');
    }

    store.dispatch('navigation/showErrorSnackbar', errResponse.data);
    return errResponse

  }
};

// export const signInWithPillarCustomToken = async (token) => {
//   //Set state persistence to 'null' (the state will only be stored in memory and will be cleared when the window or activity is refreshed.)
//   const user = await setPersistence(fbAuth, browserSessionPersistence)
//     .then(() => {
//       return signInWithCustomToken(fbAuth, token);
//     })
//     .then((userCredential) => {
//       return userCredential.user;
//     })
//     .catch((err) => {
//       console.log(err)
//       // localStorage.removeItem('customToken');
//       // localStorage.removeItem('authTimestamp');
//       // Handle Errors here.
//     });


//   if (user) {
//     await intercomUpdateUser();
//     return user;
//   } else {
//     intercomLogout();
//     return false;
//   }
// };


export const logout = async () => {

  await signOut(fbAuth);
  localStorage.clear();

  //Restart Intercom


  intercomLogout()

  return;
};


export const resendCode = async (verificationId) => {
  const url = '/auth/token/resend-verification';

  try {
    const response = await http.post(url, { verificationId });
    const data = response.data.data;

    return successResponse(data)
  } catch (err) {

    const errResponse = errorResponse(err);
    store.dispatch('navigation/showErrorSnackbar', errResponse.data);
    return errorResponse(err);
  }
};

export const generateHashCode = async (userId) => {

  const url = `/auth/intercom/generate-hash`;

  try {
    const response = await http.post(url, { userId });
    return response;

  } catch (err) {
    return err;
  }

};


