
export default async (to, from, next) => {
    const { query } = to;


    if (query.state && query.state.includes('webview=true')) {

        const updatedState = query.state.replace('webview=true', '');
        const updatedQuery = { ...query, state: updatedState };

        const env = process.env.VUE_APP_ENV === 'production' ? 'PRODUCTION' : 'STAGING';
        const baseUrl = window.location.origin;
        const baseWebviewUrl = `VUE_APP_WEBVIEW_REDIRECT_BASE_URL_${env}`;


        const updatedURL = `${process.env[baseWebviewUrl]}/obconsentcomplete?redirectLink=${baseUrl}${to.path}?${new URLSearchParams(updatedQuery).toString()}`;
        window.location.href = updatedURL;
    } else {
        next();
    }

}