import router from '.';
import { addBreadcrumb } from '@sentry/vue';
import { setAnalyticsUserId } from '../controllers/analytics/firebase';
import { updateApplicationsInProgress } from '../controllers/user/update';

import store from '../store';
import { LocalStorage } from '../utils/localStorage';

// Guard for /onboard (web) and /entry routes. Gets params from url and redirects depedning on checks results
export default async (to, from, next) => {

    const localStorageData = {
        applicationId: localStorage.getItem('applicationId'),
        productName: localStorage.getItem('productName'),
        uid: localStorage.getItem('uid'),
        webview: localStorage.getItem('webview'),
        token: localStorage.getItem('token'),
        tokenExpiration: localStorage.getItem('tokenExpiration'),
        verificationId: localStorage.getItem('verificationId')
    };

    addBreadcrumb({
        category: 'navigation',
        message: `LocalStorage data:`,
        module: 'entryGuard',
        data: localStorageData,
        level: 'info'
    });

    let currentUserId = null;

    /////////  GETTING QUERY PARAMS AND SAVING THEM TO STORE ////////////////
    const query = to.query;

    addBreadcrumb({
        category: 'navigation',
        message: `Entry guard check started...`,
        module: 'entryGuard',
        data: {
            route: to.path,
            query
        },
        level: 'info'
    });

    // verifiicationId in url? - save verificationid in localStorage
    if (query.verificationId) {
        LocalStorage.set({ verificationId: query.verificationId });
        // localStorage.setItem('verificationId', query.verificationId);
    }

    // If there is a token in url, save it to localStorage. Trying to sign in will happen a bit later
    if (query.token) {
        // localStorage.setItem('token', query.token);

        // Set tokenExpiration time - current time + 55min
        const timestamp = Date.now() + 1000 * 60 * 55;
        LocalStorage.set([{ token: query.token }, { tokenExpiration: timestamp }]);

        // localStorage.setItem('tokenExpiration', timestamp);
    }

    // Get params from url...
    // if applicationId === '' - remove applicationId from localStorage
    if (query.applicationId == '') {
        LocalStorage.remove('applicationId');
        await store.dispatch('auth/logout');
        // localStorage.removeItem('applicationId');
    }

    if (query.product) {
        store.commit('navigation/UPDATE_PRODUCT', query.product);
        // LocalStorage.set({ productName: query.product });
        localStorage.setItem('productName', query.product);
        addBreadcrumb({
            category: 'localStorage',
            data: {
                action: 'set',
                data: query.product
            },
            level: 'info'
        });
    }

    if (query.webview && query.webview == 'true') {
        LocalStorage.set({ webview: query.webview });
        // localStorage.setItem('webview', query.webview);
    }

    // Check if any leadInfo is in the url and save it to localStorage
    if (query) {
        createLeadInfo(query);
    }

    /////////  TRY TO LOG IN ////////////////

    const token = localStorage.getItem('token');
    if (token) {
        const authReq = await store.dispatch('auth/trySignIn');
        //  If success - rewrite currentUserId 
        if (authReq.status) {
            currentUserId = authReq.data.uid;
            setAnalyticsUserId(currentUserId);
        }
    }

    /////////  USER MODULE CHECK ////////////////

    // userModule=true and module name in url, user is authenticated and it is webview. check if the route can be a userModule and if user has approved product required for a route
    if (query.userModule && query.module && query.webview) {

        addBreadcrumb({
            category: 'navigation',
            message: `User module`,
            module: 'entryGuard',
            data: {
                route: to.path,
                query: query
            },
            level: 'info'
        });

        // If it is a user module, remove applciationId and productName from localStorage
        LocalStorage.remove(['applicationId', 'productName']);
        // if it is a user module - check if the user has approved product
        // If it is a user module, remove applicationId and productName from localStorage

        // Check if the route can be a userModule
        const routeDetails = router.options.routes.find(i => i.path == `/${query.module}`);

        if (routeDetails) {
            const isUserModule = routeDetails.meta?.userModule;

            // Get products required for a route
            const productsRequired = routeDetails.meta?.products;

            // If the user is authenticated and a route can be a user module, check if user has required product in his approved products.
            if (currentUserId && isUserModule) {

                const userRecord = await store.dispatch('application/getUserRecord', { userId: currentUserId, forceUpdate: true });
                if (userRecord.status) {
                    const approvedProducts = userRecord.data.approvedProducts;

                    if (approvedProducts.length) {
                        const intersection = approvedProducts.filter((el) => {
                            return productsRequired.includes(el);
                        });

                        // If the user has approved product, allow navigation. If not, redirect to /error

                        if (intersection.length) {
                            next({ path: `/${query.module}` });
                            return false;
                        }
                    }
                }
            }
        }

        store.dispatch('error/throwNewError', {
            module: 'entryGuard',
            errorType: 'navigationError',
            message: 'Sorry, you are not allowed to access this page.',
            comment: `User is trying to access userModule but he does't have required product in his approved products`,
            context: {
                currentUserId,
                webview: query.webview,
                module: query.module
            }
        });

        next({ path: '/error' });
        return false;
    }

    ///////// APPLICATIONID IN URL ////////////////

    // applicationId in url and it is not ''.
    //   If true, perfom checks and if success, resume the application
    if (query.applicationId && query.applicationId !== '') {

        addBreadcrumb({
            category: 'navigation',
            module: 'entryGuard',
            message: `Application was passed in the query`,
            data: {
                applicationId: query.applicationId
            },
            level: 'info'
        });

        const applicationRequest = await store.dispatch(
            'application/getApplicationRecord',
            { applicationId: query.applicationId, forceUpdate: true }
        );

        // If applicationId is invalid or an error occured, redirect to error
        if (!applicationRequest.status) {
            // Sentry.captureMessage('Redirect to /error: applicationId is invalid or an error occurred', {
            //     data: {
            //         applicationId: query.applicationId,
            //         userId: currentUserId,
            //         module: 'entry-guard',
            //         environment: process.env.VUE_APP_ENV,
            //     }
            // });

            store.dispatch('error/throwNewError', {
                module: 'entryGuard',
                errorType: 'apiError',
                comment: `application/getApplicationRecord request failed`,
                context: {
                    queryApplicationId: query.applicationId,
                }
            });

            next({ path: '/error' });
            return false;
        }

        const applicationData = applicationRequest.data;

        // If the user is logged in, check if application belongs to current user
        if (currentUserId) {

            const userCheck = applicationData.userId == currentUserId;
            // If not - sign out and redirect to /error
            if (!userCheck) {
                store.dispatch('error/throwNewError', {
                    module: 'entryGuard',
                    errorType: 'authError',
                    comment: `Application belongs to a different user.`,
                    context: {
                        currentUserId,
                        applicationUserId: applicationData.userId,
                        queryApplicationId: query.applicationId
                    }
                });

                LocalStorage.remove('applicationId');
                await store.dispatch('auth/logout');
                // next({ path: '/error' });
                // return false;
            }
        }

        // Check is passed. Save applicationId and productName in localStorage
        // localStorage.setItem('productName', applicationData.product);
        // localStorage.setItem('applicationId', query.applicationId);

        LocalStorage.set([{ 'productName': applicationData.product }, { 'applicationId': query.applicationId, }])
        // Check if application is not  expired
        const applicationStatus = applicationData.applicationStatus;

        // If application is expired, update productName in localStorage and redirect to /expired route where user can select to create a new application
        if (applicationStatus == 'expired') {
            addBreadcrumb({
                category: 'navigation',
                module: 'entryGuard',
                message: `Application expired`,
                data: {
                    applicationId: query.applicationId,
                },
                level: 'info'
            });

            if (to.path == '/expired') {
                next();
                return false;
            } else {
                next({ path: '/expired' });
                return false;
            }
        }

        // If user is authenticated, update the latest applicationId in his user record
        if (currentUserId) {

            addBreadcrumb({
                category: 'navigation',
                module: 'entryGuard',
                message: `Updating applications in progress for the user...`,
                data: {
                    currentUserId
                },
                level: 'info'
            });

            await updateApplicationsInProgress(currentUserId, query.applicationId);
        }

        // Get the next for the applicationId in url
        const nextStep = await resumeApplicationStep(
            query.applicationId,
            currentUserId
        );

        // If the to path is same, allow navigation. If not - redirect 
        // If not to do that, we will get an infinite loop
        if (to.path == `/${nextStep}`) {
            next();
            return false;
        } else {
            next({ path: nextStep });
            return false;

        }

    }

    /////////  PRODUCT IN URL ////////////////
    if (query.product) {

        addBreadcrumb({
            category: 'navigation',
            module: 'entryGuard',
            message: `Product is in the url`,
            data: {
                product: query.product
            },
            level: 'info'
        });

        store.commit('navigation/UPDATE_PRODUCT', query.product);
        // LocalStorage.set({ productName: query.product });
        localStorage.setItem('productName', query.product);
        addBreadcrumb({
            category: 'localStorage',
            data: {
                action: 'set',
                data: query.product
            },
            level: 'info'
        });
        // localStorage.setItem('productName', query.product);
        // LocalStorage.set({ 'productName': query.product })

        // Check if we have an applicationId saved in localStorage 
        const applicationId = localStorage.getItem('applicationId');

        if (applicationId) {

            addBreadcrumb({
                category: 'navigation',
                module: 'entryGuard',
                message: `ApplicationId is in the LocalStorage`,
                data: {
                    applicationId
                },
                level: 'info'
            });

            const applicationRequest = await store.dispatch(
                'application/getApplicationRecord',
                { applicationId, forceUpdate: true }
            );

            if (applicationRequest.status) {
                const applicationData = applicationRequest.data;

                // Check if saved applicationId product is the same as in url. If not, remove an applicationId
                if (applicationData.product !== query.product) {
                    localStorage.removeItem('applicationId');
                } else {
                    const nextStep = await resumeApplicationStep(applicationId, currentUserId);
                    if (to.path == `/${nextStep}`) {
                        next();
                        return false;
                    } else {
                        next({ path: nextStep });
                        return false;

                    }
                }
            } else {
                store.dispatch('error/throwNewError', {
                    module: 'entryGuard',
                    errorType: 'apiError',
                    comment: `application/getApplicationRecord request failed`,
                    context: {
                        currentUserId,
                        applicationId
                    }
                });
            }
        }

        // If there is no applicationId in localStorage but the user is authenticated, check his applicationIdProgress 
        if (!applicationId && currentUserId) {

            addBreadcrumb({
                category: 'navigation',
                module: 'entryGuard',
                message: `No applicationId in localStorage but the user is authenticated`,
                data: {
                    currentUserId: currentUserId
                },
                level: 'info'
            });

            const userRecord = await store.dispatch('application/getUserRecord', { userId: currentUserId, forceUpdate: true });

            if (!userRecord.status) {
                store.dispatch('error/throwNewError', {
                    module: 'entryGuard',
                    errorType: 'apiError',
                    comment: `application/getUserRecord request failed`,
                    context: {
                        currentUserId
                    }
                });

                next({ path: '/error' });
                return false;
            }

            const applicationsInProgress = userRecord?.data.applicationsInProgress;

            // Check if user has applicationInProgress for selected product
            if (applicationsInProgress) {

                addBreadcrumb({
                    category: 'navigation',
                    module: 'entryGuard',
                    message: 'User has applications in progress',
                    data: {
                        applicationsInProgress: applicationsInProgress
                    },
                    level: 'info'
                });

                const applicationId = applicationsInProgress?.[query.product];

                // If yes, get applicationData
                if (applicationId) {
                    const applicationRequest = await store.dispatch('application/getApplicationRecord', { applicationId, forceUpdate: true });

                    if (applicationRequest.status) {

                        // Save applicationId to localStorage
                        LocalStorage.set({ applicationId })
                        // localStorage.setItem('applicationId', applicationId);

                        const applicationData = applicationRequest.data;

                        const applicationStatus = applicationData.applicationStatus;

                        // If application is expired, update productName in localStoarge and redirect to /expired route where user can select to create a new application
                        if (applicationStatus == 'expired') {

                            addBreadcrumb({
                                category: 'navigation',
                                module: 'entryGuard',
                                message: `Application saved in localStorage is expired`,
                                data: {
                                    applicationId,
                                },
                                level: 'info'
                            });

                            next({ path: '/expired' });
                        }

                        // If all good, Resume an application
                        const nextStep = await resumeApplicationStep(
                            applicationId,
                            currentUserId
                        );

                        if (to.path == `/${nextStep}`) {
                            next();
                            return false;
                        } else {
                            next({ path: nextStep });
                            return false;

                        }
                    } else {
                        store.dispatch('error/throwNewError', {
                            module: 'entryGuard',
                            errorType: 'apiError',
                            comment: `application/getApplicationRecord request failed`,
                            context: {
                                applicationInProgress: applicationId
                            }
                        });
                    }
                }
            }
        }

        // If there is no applicationId in localStorage, pass productInUrl 
        const nextStep = await newApplicationStep(query.product, currentUserId);
        if (to.path == `/${nextStep}`) {
            next();
            return false;
        } else {
            next({ path: nextStep });
            return false;

        }

    }

    /////////  APPLICATIONID IN LOCALSTORAGE ////////////////

    const applicationId = localStorage.getItem('applicationId');

    if (applicationId) {

        addBreadcrumb({
            category: 'navigation',
            module: 'entryGuard',
            message: 'ApplicationId is in localStorage',
            data: {
                applicationLocalStorage: applicationId
            },
            level: 'info'
        });

        const applicationRequest = await store.dispatch('application/getApplicationRecord', { applicationId, forceUpdate: true });

        // If applicationId is invalid or an error occured, remove applicationId from localStorage
        if (!applicationRequest.status) {

            store.dispatch('error/throwNewError', {
                module: 'entryGuard',
                errorType: 'apiError',
                comment: `application/getApplicationRecord request failed`,
                context: {
                    applicationLocalStorage: applicationId
                }
            });

            // localStorage.removeItem('applicationId');

            LocalStorage.remove('applicationId');
        }

        const applicationData = applicationRequest.data;

        // If the user is not loged in, redirect to /auth or /pin
        if (!currentUserId) {

            addBreadcrumb({
                category: 'navigation',
                module: 'entryGuard',
                message: 'User is not logged in',
                level: 'info'
            });

            const verificationId = localStorage.getItem('verificationId');

            if (!verificationId) {

                addBreadcrumb({
                    category: 'navigation',
                    module: 'entryGuard',
                    message: 'No verificationId - redirect to /auth',
                    level: 'info'
                });

                next({ path: '/auth' });
                return false;
            } else {

                addBreadcrumb({
                    category: 'navigation',
                    module: 'entryGuard',
                    message: 'VerificationId exists - redirect to /pin',
                    level: 'info'
                });

                next({ path: '/pin' });
                return false;
            }
        }

        //  User is authenticated. Check if application belongs to him
        const userCheck = applicationData.userId == currentUserId;

        // If not - sign out and redirect to /error
        if (!userCheck) {
            store.dispatch('error/throwNewError', {
                module: 'entryGuard',
                errorType: 'authError',
                comment: `Application saved in localStorage belongs to a different user.`,
                context: {
                    currentUserId,
                    applicationUserId: applicationData.userId,
                    applicationId
                }
            });

            // LocalStorage.remove('applicationId')
            // HERE
            // localStorage.removeItem('applicationId');
            await store.dispatch('auth/logout');
            // next({ path: '/error' });
            // return false;
        }

        // Check if application is not expired
        const applicationStatus = applicationData.applicationStatus;

        // If application is expired, update productName in localStoarge and redirect to /expired route where user can select to create a new application
        if (applicationStatus == 'expired') {

            addBreadcrumb({
                category: 'navigation',
                module: 'entryGuard',
                message: `Application expired`,
                data: {
                    applicationId,
                },
                level: 'info'
            });

            LocalStorage.set({ productName: applicationData.product })
            // localStorage.setItem('productName', applicationData.product);
            if (to.path == '/expired') {
                next();
                return false;
            } else {
                next({ path: '/expired' });
                return false;

            }
        }

        // Alk is good - resume an application
        const nextStep = await resumeApplicationStep(applicationId, currentUserId);

        if (to.path == `/${nextStep}`) {
            next();
            return false;
        } else {
            next({ path: nextStep });
            return false;

        }
    }

    /////////  PRODUCTNAME IN LOCALSTORAGE ////////////////

    const productName = localStorage.getItem('productName');

    // It there is no productName, redirect to error
    if (!productName) {
        store.dispatch('error/throwNewError', {
            module: 'entryGuard',
            errorType: 'navigationError',
            message: 'Please select a product to start an application',
            comment: `No product passed in the query or found in LocalStorage`,
        });

        next({ path: '/error' });
        return false;
    }

    const payload = {
        productName: productName,
        userId: currentUserId,
    };

    const getNextStep = await store.dispatch('navigation/getNextStep', payload);

    // If can't get the next step, redirect to /error
    if (!getNextStep.status) {

        store.dispatch('error/throwNewError', {
            module: 'entryGuard',
            errorType: 'apiError',
            comment: `navigation/getNextStep request failed`,
            context: payload
        });
        next({ path: '/error' });
        return false;
    }

    // Redirect to product first step
    next({ path: `${getNextStep.data.nextStep}` });
    return false;

};

const resumeApplicationStep = async (applicationId, userId) => {

    addBreadcrumb({
        category: 'navigation',
        message: `resumeApplicationStep()...`,
        module: 'entryGuard',
        data: {
            applicationId,
            userId
        },
        level: 'info'
    });

    // Get application next step
    const payload = {
        applicationId,
    };

    if (userId) {
        payload.userId = userId;
    }

    const getNextStep = await store.dispatch('navigation/getNextStep', payload);

    // If can't get the next step, redirect to /error
    if (!getNextStep.status) {
        store.dispatch('error/throwNewError', {
            module: 'entryGuard',
            errorType: 'apiError',
            comment: 'navigation/getNextStep request failed',
            context: payload
        });
        return '/error';
    }
    return getNextStep.data.nextStep;
};

const newApplicationStep = async (productName, userId) => {

    addBreadcrumb({
        category: 'navigation',
        message: `newApplicationStep()...`,
        module: 'entryGuard',
        data: {
            productName,
            userId
        },
        level: 'info'
    });

    const payload = {
        productName,
    };

    if (userId) {
        payload.userId = userId;
    }

    const getNextStep = await store.dispatch('navigation/getNextStep', payload);
    // If can't get the next step, redirect to /error
    if (!getNextStep.status) {
        store.dispatch('error/throwNewError', {
            module: 'entryGuard',
            errorType: 'apiError',
            comment: 'navigation/getNextStep request failed',
            context: payload
        });

        return '/error';
    }

    return getNextStep.data.nextStep;
};

function createLeadInfo(params) {
    const abrToFullNameMap = {
        'ls': 'leadSource',
        'lpid': 'leadPublisherId',
        'lspid': 'leadSubPublisherId',
        'lcmid': 'leadCampaignId',
        'lscid': 'leadSubCampaignId',
        'luid': 'leadUniqueId',
        'lcstid': 'leadCustomerId'
    };

    const leadInfo = {};

    Object.keys(abrToFullNameMap).forEach(abr => {
        if (params[abr]) {
            const fullName = abrToFullNameMap[abr];
            leadInfo[fullName] = params[abr];
        }
    });

    if (Object.keys(leadInfo).length > 0) {
        localStorage.setItem('leadInfo', JSON.stringify(leadInfo));
    }
}
