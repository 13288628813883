import { addBreadcrumb } from '@sentry/vue';
import { sendRequest } from "../config/axios/helper";
import store from "../store";

export const urls = {
    GENERATE_HASH: {
        url: '/auth/intercom/generate-hash',
        type: 'post',
        reqParams: ['userId']
    }
}

export const updateIntercomUser = async params => {

    const hashRequest = await sendRequest(urls.GENERATE_HASH, params);

    if (!hashRequest.status) {
        return hashRequest
    }

    const userRecordRequest = await store.dispatch(
        'application/getUserRecord',
        {
            userId: params.userId
        }
    );

    if (!userRecordRequest.status) {
        return userRecordRequest
    }
    const userRecord = userRecordRequest.data;

    window.Intercom('update', {
        user_id: params.userId,
        user_hash: hashRequest.data,
        name: userRecord.firstName,
        phone: userRecord.phoneNo,
        email: userRecord.emailAddress,
        firstName: userRecord.firstName,
        lastName: userRecord.lastName,
        dateOfBirth: userRecord.dateOfBirth,
        postCode: userRecord.address?.current?.postCode,
    });

    addBreadcrumb({
        category: 'auth',
        message: 'Updated Intercom user',
        level: 'info'
    });

    return {
        status: true
    }

};

export const intercomLogout = () => {
    var APP_ID = process.env.VUE_APP_INTERCOM_APP_ID;

    window.Intercom('shutdown');
    // Boot Intercom for non-logged in users
    window.Intercom('boot', {
        app_id: APP_ID,
    });
}
