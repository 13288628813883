<template>
  <button
    type="button"
    :class="[
      size ? `button-primary ${size}` : 'button-primary',
      isLoading ? 'loading' : '',
    ]"
    :disabled="disabled"
    @click="click"
  >
    <div class="loader-container" v-if="isLoading">
      <div class="loading-indicator"></div>
    </div>
    <slot v-if="!isLoading" />
  </button>
</template>

<script>
export default {
  name: 'BaseButtonPrimary',
  props: {
    size: {
      type: String,
      default: 'lg',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    click() {
      this.$emit('clicked');
    },
  },
  computed: {
    getHeight() {
      switch (this.size) {
        case 'lg':
          return '60px';

        case 'md':
          return '48px';

        case 'sm':
          return '36px';

        default:
          return '60px';
      }
    },
    isLoading() {
      return this.$store.getters['navigation/buttonPrimaryInLoadingState'];
    },
  },
};
</script>

<style lang="scss" scoped>
.button-primary {
  position: relative;
  width: 100%;
  height: 60px;
  border-radius: 8px;
  background-color: $darkestGrey;
  color: $white;
  transition: 0.3s;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: center;
  cursor: pointer;

  &.md {
    height: 48px;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: -0.01em;
  }

  &.sm {
    height: 36px;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.02em;
  }

  i {
    color: white;
  }

  &:hover {
    background-color: $black;
  }

  &:disabled {
    background-color: #edf5f5;
    cursor: not-allowed;
    pointer-events: none;
  }

  &:active {
    background-color: $black;
    box-shadow: 0px 0px 8px rgba(36, 36, 36, 0.6);
  }

  .loader-container {
    display: flex; /* use flexbox to center the loading indicator */
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .loading-indicator {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: white;
    animation: spin 1s linear infinite;
    z-index: 3;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
