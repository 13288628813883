import { http } from "../../config/axios";
import { errorResponse, successResponse } from "../services/response";

export const updateApplicationsInProgress = async (userId, applicationId, product) => {
    const url = '/user/update/applications-in-progress';

    const payload = {
        userId,
        applicationId: applicationId ?? null
    }

    if (product) {
        payload.product = product
    }


    try {
        const response = await http.post(url, payload);
        const data = response.data;
        return successResponse(data);
    } catch (err) {

        const errResp = errorResponse(err);
        return errResp
    }
};
