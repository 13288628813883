import axios from 'axios';
import { addTokenInterceptor, errorResponseInterceptor, rejectErrorInterceptor, successResponseInterceptor } from './interceptors';
import { apiURL } from '../variables';

/**
 * @description - creates an axios instance, that receives an object that contains
 * configurations to be used to make api calls
 * the configs set are the baseURL which is the base URL for the network requests
 * stored away in the *.env* file and a timeout for network request set by default to 2500ms
 * here's why {@link https://medium.com/@masnun/handling-timeout-in-axios-479269d83c68}
 *
 * Interceptors can be added to responses and request, this can be used to handle
 * general errors, and handling session timeouts etc
 */

const http = axios.create({
  baseURL: apiURL(),
  proxyHeaders: false,
  credentials: false,
  timeout: 90000,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
});


// Create a new axios instance that uses new interceptors, so we can implement it in new components and not to break exisitng code
const api = axios.create({
  baseURL: apiURL(),
  proxyHeaders: false,
  credentials: false,
  timeout: 90000,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
});

const openHttp = axios;

http.interceptors.request.use(addTokenInterceptor, rejectErrorInterceptor);

api.interceptors.request.use(addTokenInterceptor);
api.interceptors.response.use(successResponseInterceptor, errorResponseInterceptor);

export { http, openHttp, api };
