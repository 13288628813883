<template>
  <div class="input-wrap" key="input">
    <label class="checkboxGroup">
      <input
        type="checkbox"
        :id="id"
        :data-cy="id"
        required
        v-model="checked"
        @change="onChange"
        :disabled="disabled"
      />

      <span class="checkmark"></span>
      <div
        class="checkboxInput"
        @click.stop.prevent="(event) => onClick(event)"
      >
        <slot></slot>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  components: {},
  name: 'BaseCheckbox',

  props: {
    checkedByDefault: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
    preventChange: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    toggle: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      checked: false,
    };
  },
  watch: {
    toggle: function (newValue, oldValue) {
      this.checked = newValue;
      this.$emit(
        'onInput',
        this.id,
        newValue,
        newValue ? 'valid' : 'invalid'
      );
    }
  },
  mounted() {
    if (this.checkedByDefault) {
      this.checked = true;

      this.$emit(
        'onInput',
        this.id,
        this.checked,
        this.checked ? 'valid' : 'invalid'
      );
    }
  },
  
  methods: {
    onClick(event) {
      event.preventDefault();
      if (this.preventChange) {
        return false;
      } else {
        this.checked = !this.checked;
        this.onChange();
      }
    },
    onChange() {
      this.$emit(
        'onInput',
        this.id,
        this.checked,
        this.checked ? 'valid' : 'invalid'
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.checkboxGroup {
  display: block;
  position: relative;
  padding-left: 36px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-bottom: 5px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
}

/* Hide the browser's default checkbox */

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border-radius: 4px;
  background-color: white;
  border: 2px solid $middleGrey;
  margin: 6px 0;
  input:checked ~ .checkmark {
    background-color: $darkestGrey;
    border: 2px solid $darkestGrey;
  }
  :after {
    left: 8px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid $brand;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
.checkboxInput {
  padding-top: 5px;
  padding-right: 15px;

  //styleName: Complementary/Small;

  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 18px !important;
  letter-spacing: -0.02em !important;
  text-align: left !important;
}

.small {
  color: $darkestGrey !important;
}

.inputCheckbox {
  max-width: 40px;
}

.inputGroupCheckbox {
  align-items: baseline;
}

.checkmark {
  height: 16px;
  width: 16px;
  background-color: $brand;
}

.checkboxGroup {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-bottom: 8px;
}

/* Hide the browser's default checkbox */
.checkboxGroup input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border-radius: 4px;
  background-color: white;
  border: 2px solid $secondary;
  margin: 6px 0;
}

/* On mouse-over, add a grey background color */
// .checkboxGroup:hover input ~ .checkmark {
//   background-color: #ccc;
// }

/* When the checkbox is checked, add a blue background */
.checkboxGroup input:checked ~ .checkmark {
  background-color: $primary;
  border: 2px solid $primary;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkboxGroup input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkboxGroup .checkmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid $brand;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkboxInput {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
</style>
