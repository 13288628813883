<template>
  <div class="pr-bar">
    <div class="circle" :style="{ left: applicationProgress + '%' }">
      <div class="circleShadowWrap"></div>
    </div>
    <hr class="line" :style="lineStyle" />
  </div>
</template>

<script>
export default {
  computed: {
    applicationProgress() {
      return this.$store.getters['navigation/applicationProgress'];
    },
    lineStyle() {
      const darkestGrey = '#242424';
      const lightGrey = '#D5E7E2';
      const percentage = this.applicationProgress;
      return {
        background: `linear-gradient(to right, ${darkestGrey} 0%, ${darkestGrey} ${percentage}%, ${lightGrey} ${percentage}%, ${lightGrey} 100%)`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.pr-bar {
  top: calc(50% - (10px));
  position: relative;
  z-index: 1000;
  background-color: $lightGrey;
}
.line {
  border: 2px solid $lightGrey;
  width: 80px;
}

.line {
  border: none;
  height: 4px;
  width: 80px;
}

.pr-bar-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}
// .test {
//   width: 100%;
//   height: 100%;
// }
// .progressBarWrap {
//   padding: 0;
//   margin: 0;
//   position: relative;

//   max-width: 100px !important;
//   text-align: center;
//   margin: 0 auto;
//   background-color: palegreen;
// }

// .buttonBack {
//   position: absolute;
//   right: 0;
//   top: -12px;
//   z-index: 9999;
// }

.circle {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: $darkestGrey;
  border-radius: 50%;
  transition: left 0.3s ease-in-out;
  top: -2px;

  z-index: 1;

  .circleShadowWrap {
    position: relative;
    top: calc((100% - 24px) / 2);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: $lightGrey;
    opacity: 0.3;
    right: 8px;
  }
}
</style>
