<template>
  <div class="img-wrap" @click="clicked">
    <img :src="getImgUrl" :style="{ width }" aspect-ratio="1" alt="" />
  </div>
</template>

<script>
export default {
  name: 'ImgSvg',
  props: {
    image: {
      type: String,
      required: true,
    },
    format: {
      type: String,
      default: 'svg',
    },
    folder: {
      type: String,
    },
    width: {
      type: String,
      default: '100%',
    },
  },
  computed: {
    getImgUrl() {
      const img = this.image;

      if (img.startsWith('https://') || img.startsWith('http://')) {
        return img;
      }

      let format = this.format;

      if (!format) {
        format = 'svg';
      }
      try {
        if (this.folder) {
          return require(`@/assets/${this.folder}/${img}.${format}`);
        }
        return require(`@/assets/img/${img}.${format}`);
      } catch (err) {
        return false;
      }
    },
    desktopBGImageUpdated() {
      return this.$store.getters['navigation/desktopBGImageUpdated'];
    },
  },
  methods: {
    clicked() {
      this.$emit('clicked');
    },
  },
};
</script>

<style lang="scss" scoped>
.img-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}
img {
  width: 100%;
  max-width: 100% !important;
  height: auto;
}
</style>
