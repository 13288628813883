import { createRouter, createWebHistory } from 'vue-router';
import hybridGuard from './hybrid-guard';
import entryGuard from './entry-guard';
import consentRedirectGuard from './consent-redirect-guard';

const routes = [

    // Entry points 
    // app
    {
        path: '/entry',
        name: 'entry',
        beforeEnter: entryGuard,
    },
    // web
    {
        path: '/onboard',
        name: 'onboard',
        beforeEnter: entryGuard,
    },

    // Error screens
    {
        path: '/error',
        component: () => import('../components/screens/error/ErrorScreen.vue'), 
    },
    {
        path: '/offer-error',
        component: () => import('../components/screens/preContractualInfo/DecisionDeclineScreen.vue'), 
    },
    {
        path: '/blocked',
        component: () => import('../components/screens/blocked/BlockedScreen.vue'), 
    },
    {
        path: '/paused',
        component: () => import('../components/screens/paused/PausedScreen.vue'), 
    },

    // Application status screens
    {
        path: '/expired',
        component: () => import('../components/screens/applicationStatus/ApplicationExpired.vue'), 
        beforeEnter: hybridGuard
    },
    {
        path: '/pending',
        component: () => import('../components/screens/applicationStatus/ApplicationReferred.vue'), 
        beforeEnter: hybridGuard,
    },
    {
        path: '/declined',
        component: () => import('../components/UI/screens/declineScreen.vue'), 
        beforeEnter: hybridGuard,
    },
    {
        path: '/complete',
        component: () => import('../components/screens/applicationStatus/ApplicationCompleted.vue'), 
        beforeEnter: hybridGuard,
    },
    {
        path: '/waitingList',
        component: () => import('../components/screens/applicationStatus/ApplicationWaitingList.vue'), 
        beforeEnter: hybridGuard,
    },

    // Auth modules
    {
        path: '/auth',
        name: 'phoneAuth',
        component: () => import('../components/modules/PhoneAuth.vue'), 
    },
    {
        path: '/pin',
        name: 'pinAuth',
        component: () => import('../components/modules/PinAuth.vue'), 
    },

    // Modules
    {
        path: '/applicationData',
        name: 'applicationData',
        component: () => import('../components/modules/ApplicationData.vue'), 
        beforeEnter: hybridGuard,
    },

    {
        path: '/equifaxCISSearch',
        component: () => import('../components/modules/EquifaxCISSearch.vue'), 
        beforeEnter: hybridGuard,
    },
    {
        path: '/KBA',
        component: () => import('../components/modules/KBAModule.vue'), 
        beforeEnter: hybridGuard,
    },
    {
        path: '/jumio',
        component: () => import('../components/modules/JumioModule.vue'), 
        beforeEnter: hybridGuard,
    },
    {
        path: '/jumioCallback',
        component: () => import('../components/modules/jumioCallback/JumioCallback.vue'), 
        beforeEnter: hybridGuard,
    },
    {
        path: '/CISIdVerification',
        component: () => import('../components/modules/IdVerification.vue'), 
        beforeEnter: hybridGuard,
    },

    {
        path: '/employmentAndExpenditure',
        component: () => import('../components/modules/EmploymentAndExpenditure.vue'), 
        beforeEnter: hybridGuard,

    },
    {
        path: '/employmentDetails',
        component: () => import('../components/modules/EmploymentDetails.vue'), 
        beforeEnter: hybridGuard,
    },
    {
        path: '/expenditure',
        component: () => import('../components/modules/ExpenditureModule.vue'), 
        beforeEnter: hybridGuard,
    },
    {
        path: '/obSummaryBlock',
        component: () => import('../components/modules/TrueLayerOBSummaryBlock.vue'), 
        beforeEnter: hybridGuard,
        meta: { requiresDEAnimation: true }
    },
    {
        path: '/precontractualInfo',
        component: () => import('../components/modules/PreContractualInfo.vue'), 
        beforeEnter: hybridGuard,
    },
    {
        path: '/creditCardAgreement',
        component: () => import('../components/modules/CreditCardAgreement.vue'), 
        beforeEnter: hybridGuard,
    },
    {
        path: '/complyAdvantageSearch',
        component: () => import('../components/modules/ComplyAdvantageSearch.vue'), 
        beforeEnter: hybridGuard,
        meta: { requiresDEAnimation: true }
    },
    {
        path: '/experianDelphiSearchSoft',
        component: () => import('../components/modules/ExperianDelphiSearchSoft.vue'), 
        beforeEnter: hybridGuard,
        meta: { requiresDEAnimation: true }
    },
    {
        path: '/experianDelphiSearchHard',
        // component: () => import('../components/modules/ExperianDelphiSearchHard.vue'),
        beforeEnter: hybridGuard,
        meta: { requiresDEAnimation: true }
    },
    // {
    //     path: '/policyRulesAndCreditCutOffBlock',
    //     component: CreditPolicyResult,
    //     beforeEnter: hybridGuard
    // },

    {
        path: '/AIVAffordabilityPolicyRules',
        component: () => import('../components/modules/TrueLayerAIVAffordabilityPolicyRules.vue'), 
        beforeEnter: hybridGuard,
        meta: { requiresDEAnimation: true }
    },
    {
        path: '/AIVAffordabilityPolicyRulesV2',
        component: () => import('../components/modules/AIVAffordabilityPolicyRulesV2.vue'), 
        beforeEnter: hybridGuard,
        meta: { requiresDEAnimation: true }
    },

    {
        path: '/complyAdvantageCrossCore',
        component: () => import('../components/modules/ComplyAdvantageCrossCore.vue'), 
        beforeEnter: hybridGuard,
        meta: { requiresDEAnimation: true }
    },

    {
        path: '/KYCPepsSanctions',
        component: () => import('../components/modules/TrueLayerKYCPepsSanctions.vue'), 
        beforeEnter: hybridGuard,
        meta: { requiresDEAnimation: true }
    },
    {
        path: '/openBankingCISIdCheck',
        component: () => import('../components/modules/TrueLayerCISIdCheck.vue'), 
        beforeEnter: hybridGuard,
    },

    {
        path: '/openBankingIdCheck',
        component: () => import('../components/modules/TrueLayerOpenBankingIdCheck.vue'), 
        beforeEnter: hybridGuard,
    },

    {
        path: '/CISDataPull',
        component: () => import('../components/modules/CISDataPull.vue'), 
        beforeEnter: hybridGuard,
    },

    {
        path: '/finalDecisionBlock',
        component: () => import('../components/modules/FinalDecisionBlock.vue'), 
        beforeEnter: hybridGuard,
        meta: { requiresDEAnimation: true }
    },

    {
        path: '/processingApplication',
        component: () => import('../components/modules/ProcessingApplication.vue'), 
        beforeEnter: hybridGuard,
        meta: { requiresDEAnimation: true }
    },

    {
        path: '/experian',
        component: () => import('../components/modules/ThreeScores.vue'), 
        beforeEnter: hybridGuard,
    },
    {
        path: '/transunion',
        component: () => import('../components/modules/ThreeScores.vue'), 
        beforeEnter: hybridGuard,
    },
    {
        path: '/experianCrossCore',
        component: () => import('../components/modules/ExperianCrossCore.vue'), 
        beforeEnter: hybridGuard,
    },

    // TrueLayer
    {
        path: '/trueLayerConsent',
        component: () => import('../components/modules/TrueLayerConsent.vue'), 
        beforeEnter: hybridGuard,
        meta: { userModule: true, products: ['pillarMonitor', 'pillarCard'] },
    },

    {
        path: '/obconsentcomplete',
        component: () => import('../components/modules/TrueLayerConsentComplete.vue'), 
        beforeEnter: consentRedirectGuard,
        meta: { requiresBankAnimation: true }
    },
    {
        path: '/obredirect',
        component: () => import('../components/modules/ObRedirect.vue'), 
    },

    {
        path: '/trueLayerRawDataRetrieved',
        component: () => import('../components/modules/TrueLayerRawDataRetrieved.vue'), 
        beforeEnter: hybridGuard,
        meta: { requiresBankAnimation: true }

    },

    {
        path: '/firstObNameMatch',
        component: () => import('../components/modules/FirstObNameMatch.vue'), 
        beforeEnter: hybridGuard,

    },
    {
        path: '/accountScoreAnalyticsRetrieved',
        component: () => import('../components/modules/AccountScoreAnalyticsRetrieved.vue'), 
        beforeEnter: hybridGuard,
        meta: { requiresDEAnimation: true }
    },

    // User modules
    {
        path: '/openBankingConsent',
        component: () => import('../components/modules/openBankingConsent/obConsentLink.vue'), 
        beforeEnter: hybridGuard,
        meta: { userModule: true, products: ['pillarMonitor', 'pillarCard'] },
    },

    {
        path: '/obsuccess',
        component: () => import('../components/modules/openBankingConsent/OpenBankingConsent.vue'), 
        beforeEnter: hybridGuard,
        meta: { dynamic: true, userModule: true, products: ['pillarMonitor', 'pillarCard'] },
    },
    {
        path: '/allData',
        component: () => import('../components/modules/allData/AllData.vue'), 
        beforeEnter: hybridGuard,
        meta: { userModule: true, products: ['pillarMonitor', 'pillarCard'] },
    },
    {
        path: '/obfail',
        component: () => import('../components/ob/OBFailScreen'), 
        beforeEnter: hybridGuard,
        meta: { dynamic: true, userModule: true, products: ['pillarMonitor', 'pillarCard'] },
    },
    {
        path: '/openbankingebav',
        component: () => import('../components/modules/openBankingEBAV/OpenBankingEBAV.vue'), 
        beforeEnter: hybridGuard,
        meta: { userModule: true, products: ['pillarMonitor', 'pillarCard'] },
    },

    {
        path: '/directDebitSetup',
        component: () => import('../components/modules/DirectDebitSetup.vue'), 
        beforeEnter: hybridGuard,
        meta: { userModule: true, products: ['pillarCard'] },
    },

    // Rent Reporting
    {
        path: '/addressConfirmation',
        component: () => import('../components/modules/AddressConfirmation.vue'), 
        beforeEnter: hybridGuard,
    },
    {
        path: '/rentReportingTransaction',
        component: () => import('../components/modules/RentReportingTransaction.vue'), 
        beforeEnter: hybridGuard,
    },

    {
        path: '/payment',
        component: () => import('../components/modules/StripePayment.vue'), 
        beforeEnter: hybridGuard,
    },

    {
        path: '/truelayerpayment',
        component: () => import('../components/modules/TrueLayerPayment.vue'), 
        meta: { requiresBankAnimation: true }

    },

    // TrueLayer

    // pillarCardIndia

    {
        path: '/indiaBureauData',
        component: () => import('../components/modules/IndiaBureauData.vue'), 
        beforeEnter: hybridGuard,

    },
    {
        path: '/assessingApplication',
        component: () => import('../components/modules/ForceApprovalFinalDecisionBlock.vue'), 
        beforeEnter: hybridGuard,

    },

    {
        path: '/fetchScores',
        component: () => import('../components/modules/ThreeScores.vue'), 
        beforeEnter: hybridGuard,
    },

    // International journey

    {
        path: '/applicationDataNTC',
        name: 'applicationDataNTC',
        component: () => import('../components/modules/ApplicationDataNTC.vue'), 
        beforeEnter: hybridGuard,
    },
    {
        path: '/foreignDataCapture',
        component: () => import('../components/modules/ForeignDataCapture.vue'), 
        beforeEnter: hybridGuard,
    },
    {
        path: '/foreignCreditReportPull',
        component: () => import('../components/modules/ForeignCreditReportPull.vue'), 
        beforeEnter: hybridGuard,
    },

    {
        path: '/foreign-credit-report-pull',
        children: [
            {
                path: 'success',
                name: 'foreignCreditReportPullSuccess',
                component: () => import('../components/screens/foreignCreditReportPull/ForeignCreditReportPullSuccess.vue'), 
            },
            {
                path: 'fail',
                name: 'foreignCreditReportPullFail',
                component: () => import('../components/screens/foreignCreditReportPull/ForeignCreditReportPullFail.vue'), 
            }
        ]
    },
    {
        path: '/employmentAndExpenditureNTC',
        component: () => import('../components/modules/EmploymentAndExpenditureNTC.vue'), 
        beforeEnter: hybridGuard,

    },
    {
        path: '/foreignDataCapture',
        component: () => import('../components/modules/ForeignDataCapture.vue'), 
        beforeEnter: hybridGuard,
    },
    {
        path: '/jubio',
        component: () => import('../components/modules/JubioModule.vue'), 
        beforeEnter: hybridGuard,
    },
    {
        path: '/UKAddress',
        component: () => import('../components/modules/UKAddress.vue'), 
        beforeEnter: hybridGuard,
    },
    {
        path: '/',
        redirect: '/onboard'
    },
    {
        path: '/:pathMatch(.*)',
        redirect: '/onboard'
    }

];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router; // Change this line to export default