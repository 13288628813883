import { http } from "../config/axios";
import { logFirebaseEvent } from "../controllers/analytics/firebase";
import { errorResponse } from "../controllers/services/response";
import router from "../router";

const state = {
    isLoading: true,
    animationMode: 'normal',
    routeLoading: true,
    applicationProgress: 0,
    productName: null,
    currentStep: {
        name: null,
        passedBefore: false
    },
    OBPassedBefore: false,
    OBSummaryPassedBefore: false,
    firstRoute: true,
    routeIsLoading: false,
    moduleIsLoading: false,
    loadingText: null,
    requestInProgress: false,
    moduleTitle: null,
    progressBarVisible: true,
    buttonPrimaryInLoadingState: false,
    buttonSecondaryInLoadingState: false,
    history: null,
    customDesktopBGImage: null,
    loaderAnimationType: 'default',
    loaderAnimationText: null,
    activeScreen: null,
    hasVerticalScroll: false,
    footerHeight: 0,
    contentCentered: false,
    desktopBGHidden: false,
    loaderOpacity: 1,
    loaderProviderId: null,
    progressPerStep: null,
    screensPerModule: 0
};

const mutations = {
    UPDATE_PRODUCT (state, payload) {
        state.productName = payload;
    },
    PROGRESS_BAR_STATUS_UPDATED (state, payload) {
        state.progressBarVisible = payload;
    },
    SET_LOADING (state, isLoading) {
        state.isLoading = isLoading;
    },
    SET_ANIMATION_MODE (state, mode) {
        state.animationMode = mode;
    },
    SET_ERROR_MESSAGE (state, message) {
        state.errorMessage = message;
    },
    CURRENT_STEP_UPDATED (state, payload) {
        state.currentStep = {
            name: payload.stepName,
            passedBefore: payload.passedBefore ? payload.passedBefore : false
        };
    },
    APPLICATION_PROGRESS_UPDATED (state, payload) {
        state.applicationProgress = Math.round(payload);
    },

    SET_PROGRESS_PER_STEP (state, payload) {
        state.progressPerStep = Math.round(payload);
    },

    SET_SCREENS_PER_MODULE (state, payload) {
        state.screensPerModule = payload;
    },
    OB_PASSED_BEFORE_UPDATED (state, payload) {
        state.OBPassedBefore = payload;
    },

    OB_SUMMARY_PASSED_BEFORE_UPDATED (state, payload) {
        state.OBSummaryPassedBefore = payload;
    },
    MODULE_TITLE_UPDATED (state, payload) {
        state.moduleTitle = payload;
    },

    ROUTE_IS_LOADING_UPDATED (state, payload) {
        state.routeIsLoading = payload;
    },

    FIRST_ROUTE_STATE_UPDATE (state, payload) {
        state.firstRoute = payload;
    },

    ERROR_SCREEN_STATE_UPDATE (state, payload) {
        state.errorScreenActive = payload;
    },

    ERROR_SNACKBAR_STATE_UPDATE (state, payload) {
        state.errorSnackbarActive = payload;
    },

    ERROR_MESSAGE_UPDATE (state, payload) {
        state.errorMessage = payload;
    },

    MODULE_LOADING_STATE_UPDATE (state, payload) {
        state.moduleIsLoading = payload;
    },
    RESET_STORE (state, payload) {
        Object.assign(state, payload);
    },

    LOADING_TEXT_UPDATE (state, payload) {
        state.loadingText = payload;
    },

    REQUEST_IN_PROGRESS_UPDATE (state, payload) {
        state.requestInProgress = payload;
    },
    BUTTON_PRIMARY_LOADING_UPDATE (state, payload) {
        state.buttonPrimaryInLoadingState = payload;
    },

    BUTTON_SECONDARY_LOADING_UPDATE (state, payload) {
        state.buttonSecondaryInLoadingState = payload;
    },

    UPADTE_SCREEN (state, payload) {

        if (!state.history) {
            state.history = [];
        }

        let subProgress = 0;

        if (state.progressPerModule) {
            subProgress = Math.round(state.progressPerModule / state.screensPerModule);
        }

        if (payload === 'previousScreen') {
            // update progress
            if (state.progressPerModule) {
                state.applicationProgress -= subProgress;
            }
            state.history.shift();
        } else {
            if (state.progressPerModule) {
                state.applicationProgress += subProgress;
            }
            state.history.unshift(payload);
        }

        if (state.history && state.history.length) {
            state.activeScreen = state.history[0];
        } else {
            state.activeScreen = null;
        }

        window.scrollTo(0, 0);

        // if (payload) {

        //   if (payload == 'previousScreen') {
        //     const historyCopy = [...state.activeScreen]
        //     historyCopy.shift(1);
        //     state.activeScreen = historyCopy;

        //   } else if (!state.activeScreen) {

        //     state.activeScreen = [payload]
        //   } else {
        //     const historyCopy = [...state.activeScreen]
        //     historyCopy.unshift(payload);
        //     state.activeScreen = historyCopy;
        //   }

        // } else {
        //   state.activeScreen = []
        // }

    },

    UPDATE_ANIMATION_MODE (state, payload) {
        state.animationMode = payload;
    },

    UPDATE_ERROR_IMAGE (state, payload) {
        state.errorImage = payload;
    },

    UPDATE_ERROR_POPUP_MESSAGE (state, payload) {
        state.errorPopupMessage = payload;
    },

    UPDATE_CUSTOM_DESKTOP_BG_IMAGE (state, payload) {
        state.customDesktopBGImage = payload;
    },
    UPDATE_LOADER_ANIMATION_TYPE (state, payload) {
        state.loaderAnimationType = payload;
    },

    UPDATE_PROVIDER_ID (state, payload) {
        state.loaderProviderId = payload;
    },
    UPDATE_LOADER_ANIMATION_TEXT (state, payload) {
        state.loaderAnimationText = payload;
    },
    SET_VERTICAL_SCROLL (state, payload) {
        state.hasVerticalScroll = payload;
    },
    SET_FOOTER_HEIGHT (state, payload) {
        state.footerHeight = payload;
    },
    SET_CONTENT_CENTERED (state, payload) {
        state.contentCentered = payload;
    },
    HIDE_DESKTOP_BG (state, payload) {
        state.desktopBGHidden = payload;
    },
    SET_LOADER_OPACITY (state, payload) {
        state.loaderOpacity = payload;
    },
    SET_SUBSTEPS_NUMBER (state, payload) {
        state.substepsNumber = payload;
    },
};

const actions = {
    startLoading ({ commit }) {
        commit('SET_LOADING', true);
    },
    stopLoading ({ commit }) {
        commit('UPDATE_LOADER_ANIMATION_TEXT', null);
        commit('SET_LOADING', false);
        commit('UPDATE_LOADER_ANIMATION_TYPE', 'default');

    },
    setError ({ commit }, message) {
        commit('SET_ERROR_MESSAGE', message);
    },
    clearError ({ commit }) {
        commit('SET_ERROR_MESSAGE', null);
    },

    async getNextStep ({ commit, dispatch }, payload) {
        // dispatch('hideErrorSnackbar');

        let sendData = {};

        const applicationId = payload?.applicationId || null;
        const productName = payload?.productName || null;
        const userId = payload?.userId || null;
        const selectedOption = payload?.selectedOption || null;

        if (productName) sendData.product = productName;
        if (applicationId) { sendData.applicationId = applicationId; delete sendData.product; }
        if (userId) sendData.userId = userId;
        if (selectedOption) sendData.selectedOption = selectedOption;

        let url = '/utilities/product-config';

        try {
            const response = await http.post(url, sendData);

            const data = response.data.data;

            const stepName = data.nextStep;
            const passedBefore = data?.passedBefore || false;
            const applicationProgress = data?.applicationProgress || 0;
            const applicationId = data?.applicationId;

            if (applicationId) {
                localStorage.setItem('applicationId', applicationId);
            }

            const newModuleData = {
                stepName,
                passedBefore
            };

            commit('CURRENT_STEP_UPDATED', newModuleData);

            if (applicationProgress) {
                commit('APPLICATION_PROGRESS_UPDATED', applicationProgress);
            }

            if (data?.progressPerStep) {
                commit('SET_PROGRESS_PER_STEP', data.progressPerStep);
            }
            // dispatch('routeLoadingFinish')
            return {
                status: true,
                data
            };
            // store.dispatch('firstEntryStateCancel');
            // store.dispatch('hideSpinner');

            // if (router.currentRoute.path != `/${stepName}`) {
            //     router.push(`/${stepName}`)
            // }

        } catch (err) {

            const errResp = errorResponse(err);

            if (errResp.statusCode == 403) {
                router.push('/paused');
                return;
            }

            dispatch('setErrorMessage', errResp.data);
            dispatch('showErrorScreen');
            dispatch('routeLoadingFinish');

            return errResp;

        }
    },

    async modulePassed ({ dispatch }, payload) {

        const event = payload.event || null;
        if (event) {
            logFirebaseEvent(event);
        }

        const applicationId = localStorage.getItem('applicationId');
        const data = {
            applicationId
        };

        if (payload.selectedOption) {
            data.selectedOption = payload.selectedOption;
        }

        const nextStepReq = await dispatch('getNextStep', data);

        if (!nextStepReq.status) {
            return false;
        }

        const nextStep = nextStepReq.data.nextStep;

        router.push(`/${nextStep}`).catch(() => { });
        // if (this.$route.path != `/${nextStep}`) {
        //     this.$router.replace(`/${nextStep}`);
        // }

    },
    setSubstepsNumber ({ commit }, payload) {
        commit('SET_SUBSTEPS_NUMBER', payload);
    },
    hideProgessBar ({ commit }) {
        commit('PROGRESS_BAR_STATUS_UPDATED', false);
    },
    setOBPassedBefore ({ commit }) {
        commit('OB_PASSED_BEFORE_UPDATED', true);
    },
    routeLoadingFinish ({ commit }) {
        commit('ROUTE_IS_LOADING_UPDATED', false);
    },

    showErrorScreen ({ commit, dispatch }) {
        commit('ERROR_SCREEN_STATE_UPDATE', true);
        dispatch('routeLoadingFinish');
    },

    hideErrorScreen ({ commit, dispatch }) {
        commit('ERROR_SCREEN_STATE_UPDATE', false);
        dispatch('setErrorMessage', null);
    },

    setErrorMessage ({ commit }, payload) {
        commit('ERROR_MESSAGE_UPDATE', payload);
    },

    firstRouteStateCancel ({ commit }) {
        commit('FIRST_ROUTE_STATE_UPDATE', false);
    },

    moduleLoadingStart ({ commit }) {
        commit('MODULE_LOADING_STATE_UPDATE', true);
    },

    moduleLoadingFinish ({ commit }) {
        commit('MODULE_LOADING_STATE_UPDATE', false);
    },

    loadingTextUpdate ({ commit }, payload) {
        commit('LOADING_TEXT_UPDATE', payload);
    },

    requestStart ({ commit }) {
        commit('REQUEST_IN_PROGRESS_UPDATE', true);
    },

    requestFinish ({ commit }) {
        commit('REQUEST_IN_PROGRESS_UPDATE', false);
    },
    showErrorSnackbar ({ commit, dispatch }, payload) {
        dispatch('setErrorMessage', payload);

        commit('ERROR_SNACKBAR_STATE_UPDATE', true);

    },
    hideErrorSnackbar ({ commit, dispatch }) {

        commit('ERROR_SNACKBAR_STATE_UPDATE', false);
        dispatch('setErrorMessage', null);

    },

    buttonPrimaryInLoadingState ({ commit }, payload) {
        commit('BUTTON_PRIMARY_LOADING_UPDATE', payload);
    },

    buttonSecondaryInLoadingState ({ commit }, payload) {
        commit('BUTTON_SECONDARY_LOADING_UPDATE', payload);
    },

    updateScreen ({ commit }, payload) {
        commit('UPADTE_SCREEN', payload);
    },

    backToPreviousScreen ({ commit }) {
        commit('UPADTE_SCREEN', 'previousScreen');
    },

    setErrorImage ({ commit }, payload) {
        commit('UPDATE_ERROR_IMAGE', payload);
    },
    setCustomDesktopBG ({ commit }, payload) {
        commit('UPDATE_CUSTOM_DESKTOP_BG_IMAGE', payload);
    },
    updateLoaderAnimationType ({ commit }, payload) {
        commit('UPDATE_LOADER_ANIMATION_TYPE', payload);
    },
    updateLoaderProviderId ({ commit }, payload) {
        commit('UPDATE_PROVIDER_ID', payload);
    },
    updateLoaderAnimationText ({ commit }, payload) {

        commit('UPDATE_LOADER_ANIMATION_TEXT', payload);
    },
    initialRenderLogDone ({ commit }) {
        commit('INITIAL_RENDER_LOG_STATUS', true);
    },
    changeLoaderOpacity ({ commit }, payload) {
        commit('SET_LOADER_OPACITY', payload);
    }

};

const getters = {
    isLoading: (state) => state.isLoading,
    animationMode: (state) => state.animationMode,
    errorMessage: (state) => state.errorMessage,
    nextStep: state => state.currentStep.name,
    stepPassedBefore: state => state.currentStep.passedBefore,
    OBPassedBefore: state => state.OBPassedBefore,
    OBSummaryPassedBefore: state => state.OBSummaryPassedBefore,
    firstRoute: state => state.firstRoute,
    routeIsLoading: state => state.routeIsLoading,
    errorScreenActive: (state) => state.errorScreenActive,
    errorSnackbarActive: (state) => state.errorSnackbarActive,
    loadingText: (state) => state.loadingText,
    moduleIsLoading: (state) => state.moduleIsLoading,
    requestInProgress: (state) => state.requestInProgress,
    moduleTitle: (state) => state.moduleTitle,
    progressBarVisible: (state) => state.progressBarVisible,
    routeLoading: (state) => state.routeLoading,
    buttonPrimaryInLoadingState: (state) => state.buttonPrimaryInLoadingState,
    buttonSecondaryInLoadingState: (state) => state.buttonSecondaryInLoadingState,
    errorImage: (state) => state.errorImage,
    errorTitle: (state) => state.errorTitle,
    errorPopupMessage: (state) => state.errorPopupMessage,
    applicationProgress: (state) => state.applicationProgress,
    customDesktopBGImage: (state) => state.customDesktopBGImage,
    loaderAnimationType: (state) => state.loaderAnimationType,
    loaderAnimationText: (state) => state.loaderAnimationText,
    activeScreen: (state) => state.activeScreen,
    backButtonVisible: (state) => {
        const isWebview = localStorage.getItem('webview');
        return !!isWebview || !!state.activeScreen; // assuming activeScreen is the relevant state
    },
    headerIsVisible: (state, getters) => {
        return getters.backButtonVisible || getters.progressBarVisible;
    },
    loaderOpacity: (state) => state.loaderOpacity,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
