<template>
  <button type="button" :data-cy="id" @click="click" class="base-button-icon">
    <BaseIcon :icon="icon" :size="size" isButton />
  </button>
</template>

<script>
import BaseIcon from '../assets/BaseIcon.vue';
export default {
  components: { BaseIcon },
  name: 'BaseButtonIcon',
  props: {
    id: {
      type: String,
    },
    icon: {
      type: String,
      required: true,
    },
    size: {
      type: String,
    },
  },
  methods: {
    click() {
      this.$emit('clicked');
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  background-color: transparent;
  padding: 4px;
  border: none; /* Remove borders */
  cursor: pointer; /* Mouse pointer on hover */
}

@media (hover: hover) {
  .base-button-icon {
    :hover,
    :active {
      background-color: transparent;
    }
  }
}
</style>
