import store from "../../store";

export const Authorization = {
  get: {
    customToken() {
      return store.getters.token;
      // return localStorage.getItem('customToken');
    },
  },
  set: {
    // customToken(token) {
    //   // Then Store in LocalStorage
    //   return // localStorage.setItem('customToken', token);
    // },
    // URLIntended(url) {
    //   return // localStorage.setItem('FROM', url);
    // },
  },
  clear: {
    customToken() {
      return // localStorage.removeItem('customToken');
    },
    URLIntended() {
      return // localStorage.removeItem('FROM');
    },
  },
  check: {
    isAuthenticated() {
      return !!Authorization.get.customToken();
    },
  },
};