import { createStore } from 'vuex';
import navigationModule from './navigation';
import applicationModule from './application';
import authModule from './auth';
import errorModule from './error';

const store = createStore({
    modules: {
        navigation: navigationModule,
        application: applicationModule,
        auth: authModule,
        error: errorModule
    },
});

export default store;