<template>
  <div
    :style="{ height: getSize, width: getSize }"
    :class="isButton ? 'icon-wrap' : 'icon-wrap not-button'"
  >
    <img :src="getImgUrl" alt="" />
  </div>
</template>

<script>
export default {
  name: 'BaseIcon',
  props: {
    icon: {
      type: String,
      required: true,
    },
    size: {
      type: String,
    },
    isButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getImgUrl() {
      try {
        return require(`@/assets/icons/${this.icon}.svg`);
      } catch (err) {
        return null;
      }
    },

    getSize() {
      let size;

      switch (this.size) {
        case 'sm':
          size = 16;
          break;
        case 'md':
          size = 24;
          break;
        case 'lg':
          size = 48;
          break;
        case 'xl':
          size = 60;
          break;
        default:
          size = this.size;
          break;
      }

      if (!size) {
        size = 32;
      }

      return `${+size}px`;
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.not-button {
  img {
    width: 75%;
    height: auto;
  }
}
</style>
