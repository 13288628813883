<template>
    <div class="modal">
        <div
            class="modal-overlay"
            :class="{ 'show-overlay': showModal }"
            @click="closeDialog"
        ></div>
        <div class="modal-content" :class="{ 'show-content': showModal }">
            <div
                v-if="!noCloseButton"
                class="close-button"
                @click="closeDialog"
            >
                <BaseIcon icon="close" :isButton="true" />
            </div>

            <div
                ref="scrollableContent"
                class="modal-body"
                :style="{ marginTop: !noCloseButton ? '20px' : 0 }"
            >
                <h1 v-if="title">
                    {{ title }}
                </h1>
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import BaseIcon from '../assets/BaseIcon.vue';

export default {
    name: 'BaseModal',
    components: { BaseIcon },
    props: {
        title: {
            type: String,
        },
        noCloseButton: {
            type: Boolean,
            default: false,
        },
        canBeClosed: {
            default: true,
            type: Boolean,
        },
    },
    data() {
        return {
            showModal: false,
        };
    },
    computed: {
        headerIsVisible() {
            return this.title || !this.noCloseButton;
        },
    },
    mounted() {
        this.$nextTick(() => {
            if (this.$refs.scrollableContent) {
                this.$refs.scrollableContent.scrollTop = 0;
            }
            this.showModal = true;
            this.toggleBodyScroll(true); // Enable body scrolling
        });
    },
    unmounted() {
        this.toggleBodyScroll(false); // Disable body scrolling when modal is closed or component is destroyed
    },
    methods: {
        closeDialog() {
            if (this.canBeClosed) {
                this.showModal = false;
                this.$emit('closeModal');
            }
        },

        toggleBodyScroll(enable) {
            const body = document.body;

            if (enable) {
                body.classList.add('modal-open');
            } else {
                body.classList.remove('modal-open');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
body.modal-open {
    overflow: hidden !important;
    max-height: 100vh !important;
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    z-index: 10000;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.65);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;
}

.show-overlay {
    opacity: 1;
    pointer-events: auto;
}

.modal-content {
    background-color: white;
    max-width: 460px;
    width: 70% !important;
    padding: 24px 48px;
    border-radius: 32px;
    opacity: 0;
    transform: translateY(250px);
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    pointer-events: none;
    box-shadow: 0px 20px 40px -16px rgba(110, 120, 119, 0.16);

    h1 {
        font-size: 24px !important;
        font-weight: 600 !important;
        line-height: 32px !important;
        letter-spacing: -0.02em !important;
        text-align: left;
        color: $darkestGrey;
        margin-top: 0px;
        text-align: center;
    }
}

.show-content {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
    overflow-y: auto;
    max-height: 90vh;
}

.close-button {
    position: absolute;
    right: 18px;
    top: 20px;
}
</style>
