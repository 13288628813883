import { logEvent, setUserId } from "firebase/analytics";
import { analytics } from '../../main';
import store from '../../store';

export const logFirebaseEvent = (event, parameters) => {

    let params = {
        ...parameters,
        timestamp: Date.now()
    };

    const uid = store.getters.userId;

    if (uid) {
        params.userId = uid;
    }

    const webview = localStorage.getItem('webview');

    if (webview) params.webview = 'true';
    else params.webview = 'false';

    // Attach the applicationId to the event if it exists
    const applicationId = localStorage.getItem('applicationId');
    if (applicationId) params.applicationId = applicationId;

    // Attach the product name to the event
    const product = localStorage.getItem('productName');
    if (product) params.product = product;

    // Log the event to analytics and attach a timestamp
    logEvent(analytics, event, params);
};

export const setAnalyticsUserId = (userId) => {
    setUserId(analytics, userId);
};
