const extractDataSuccessResponse = (response) => {
    let dataObj = response;


    while (Object.prototype.hasOwnProperty.call(dataObj, 'data')) {

        dataObj = dataObj.data
    }



    return {
        status: true,
        data: dataObj
    }

}


export const successResponse = (response) => {
    return extractDataSuccessResponse(response);


}

export const errorResponse = (err) => {
    const statusCode = err.response.status;


    let friendlyMessage = 'An unknown error occurred, please try again or contact support.';

    if (err.response.data?.meta?.friendlyMessage) {
        friendlyMessage = err.response.data?.meta?.friendlyMessage;
    }

    return {
        status: false,
        statusCode: statusCode,
        data: friendlyMessage
    }

}