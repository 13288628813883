const env = process.env.VUE_APP_ENV;

export const apiURL = () => {
    switch (env) {
        case 'local':
        case 'github':
            return process.env.VUE_APP_LOCAL_API_URL;
        case 'production':
            return process.env.VUE_APP_PRODUCTION_API_URL;
        case 'staging':
            return process.env.VUE_APP_STAGING_API_URL;
        default:
            return process.env.VUE_APP_LOCAL_API_URL;
    }
};

export const stripePlans = () => {
    let annualPrice;
    let monthlyPrice;

    switch (env) {
        case 'local':
            annualPrice = process.env.VUE_APP_STRIPE_PLAN_ID_ANNUAL_STAGING;
            monthlyPrice = process.env.VUE_APP_STRIPE_PLAN_ID_MONTHLY_STAGING;
            break;
        case 'github':
            annualPrice = process.env.VUE_APP_STRIPE_PLAN_ID_ANNUAL_STAGING;
            monthlyPrice = process.env.VUE_APP_STRIPE_PLAN_ID_MONTHLY_STAGING;
            break;
        case 'production':
            annualPrice = process.env.VUE_APP_STRIPE_PLAN_ID_ANNUAL_PROD;
            monthlyPrice = process.env.VUE_APP_STRIPE_PLAN_ID_MONTHLY_PROD;
            break;
        case 'staging':
            annualPrice = process.env.VUE_APP_STRIPE_PLAN_ID_ANNUAL_STAGING;
            monthlyPrice = process.env.VUE_APP_STRIPE_PLAN_ID_MONTHLY_STAGING;
            break;
        default:
            annualPrice = process.env.VUE_APP_STRIPE_PLAN_ID_ANNUAL_STAGING;
            monthlyPrice = process.env.VUE_APP_STRIPE_PLAN_ID_MONTHLY_STAGING;
            break;
    }


    return { annualPrice, monthlyPrice };
}


export const stripePublicKey = () => {
    let stripeKey;

    switch (env) {
        case 'local':
            stripeKey = process.env.VUE_APP_STRIPE_PUBLIC_KEY_STAGING;
            break;
        case 'github':
            stripeKey = process.env.VUE_APP_STRIPE_PUBLIC_KEY_STAGING;
            break;
        case 'production':
            stripeKey = process.env.VUE_APP_STRIPE_PUBLIC_KEY_PROD;
            break;
        case 'staging':
            stripeKey = process.env.VUE_APP_STRIPE_PUBLIC_KEY_STAGING;
            break;
        default:
            stripeKey = process.env.VUE_APP_STRIPE_PUBLIC_KEY_STAGING;
            break;
    }


    return stripeKey;
}