import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";
import { getLimitedUseToken, initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
import router from './router';
import { createGtm } from '@gtm-support/vue-gtm';
import { firebaseConfig } from './firebaseConfig';
import { gtmConfig } from './gtmConfig';
import { initializeIntercom } from './intercom';
import './styles/app.scss';
import { logFirebaseEvent } from './controllers/analytics/firebase';
import {init, browserTracingIntegration, replayIntegration, withScope, captureException} from "@sentry/vue";
import Firestore from './utils/firestore';
import { initialize } from 'launchdarkly-js-client-sdk';

const VueApp = createApp(App);

// Initialize Firebase and Auth
const app = initializeApp(firebaseConfig);

const isDebugModeEnabled = process.env.VUE_APP_ENV === 'local';

// Enable debug mode for Firebase App Check token verification in the local environment
if (isDebugModeEnabled) {
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.VUE_APP_DEBUG_TOKEN;
}

const env = process.env.VUE_APP_ENV === 'production' ? 'PRODUCTION' : 'STAGING';

const recaptchaKey = `VUE_APP_RECAPTCHA_SITE_KEY_${env}`;
const siteKey = process.env[recaptchaKey];

// Initialize appCheck with the appropriate options
export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(siteKey),
  isTokenAutoRefreshEnabled: !isDebugModeEnabled,
});

export const fbAuth = getAuth(app);
export const analytics = getAnalytics(app);

if (process.env.VUE_APP_ENV == 'local') {
  connectAuthEmulator(fbAuth, 'http://localhost:9099');
}

VueApp.use(createGtm(gtmConfig));

const intercomAppId = process.env.VUE_APP_INTERCOM_APP_ID;
initializeIntercom(intercomAppId);

init({
  app: VueApp,
  dsn: "https://c6ca31a218594726a4ba379a82b23769@o1175657.ingest.sentry.io/6272666",
  integrations: [
    browserTracingIntegration(),
    replayIntegration(),
  ],

  tracesSampleRate: 1.0,
  logErrors: true,
  environment: process.env.VUE_APP_ENV,
  maxValueLength: 999,
  autoSessionTracking: true,
  attachStacktrace: true,
});

export const getAppCheckToken = async () => {
  try {
    const appCheckTokenResponse = await getLimitedUseToken(appCheck);
    const token = appCheckTokenResponse.token;
    if (token) {
      return token;
    } else {
      throw new Error('Failed to execute reCAPTCHA and App check');
    }
  } catch (error) {
    const applicationId = localStorage.getItem('applicationId');
    const uid = localStorage.getItem('uid');
      withScope((scope) => {
      scope.setContext('Failed to execute reCAPTCHA and App check:', { applicationId, userId: uid });
      captureException(error);
    });
  }
};

// Initialize LaunchDarkly with an anonymous user
const ldClientIdName = `VUE_APP_LAUNCH_DARKLY_CLIENT_ID_${env}`;
const ldClientId = process.env[ldClientIdName];
const anonymousUser = {
  key: 'anonymous',
  anonymous: true
};

export const ldClient = initialize(ldClientId, anonymousUser);

router.beforeEach(async (to, from, next) => {

  const currentAnimation = store.state.navigation.loaderAnimationType;

  if (to.meta?.requiresBankAnimation) {

    if (currentAnimation !== 'bank') {
      if (to.query) {
        const providerId = extractProviderIdFromUrl(to);

        store.dispatch('navigation/updateLoaderProviderId', providerId);
      } else {
        const userId = store.getters['auth/userId'];

        if (userId) {
          const lastLog = await Firestore.getDocs(
            `Users/${userId}/TrueLayerLogs`,
            { orderBy: 'createdAtTimestamp', limit: 1 }
          );

          if (lastLog.length > 0) {
            const providerId = lastLog[0].providerId;

            if (providerId) {
              store.dispatch('navigation/updateLoaderProviderId', providerId);
            }
          }

        }

      }

      store.dispatch('navigation/updateLoaderAnimationType', 'bank');
    }
  }

  if (to.meta?.requiresDEAnimation) {

    if (currentAnimation !== 'de') {
      store.dispatch('navigation/updateLoaderAnimationType', 'de');
    }

  }
  next()
})

// TODO: cleanup before and after

router.afterEach((to) => {
  store.commit('navigation/PROGRESS_BAR_STATUS_UPDATED', true);
  store.dispatch('navigation/routeLoadingFinish');
  // store.commit('navigation/UPDATE_DESKTOP_BG', true);
  store.dispatch('navigation/buttonSecondaryInLoadingState', false);
  store.dispatch('navigation/buttonPrimaryInLoadingState', false);
  store.dispatch('navigation/requestFinish')

  store.commit('navigation/UPADTE_SCREEN', null);
  window.scrollTo(0, 0)
  // Remove first slash
  const pathTo = to.path.replace('/', '');

  // Find the next slash
  const indexOf = pathTo.indexOf("/");

  // Construct the new string if needed
  const newPath = indexOf != -1 ? pathTo.slice(0, indexOf) : pathTo;

  // Log the page view in analytics when landed
  logFirebaseEvent('page_view', {
    page_title: newPath
  });
});

VueApp.use(router);
VueApp.use(store);

ldClient.on('ready', () => {
  VueApp.mount('#app');
});

// getAppCheckToken().then(() => {
//   VueApp.mount('#app');
// });

// Get providerId (bank) to show ob animation
function extractProviderIdFromUrl(to) {
  // Directly use route.query to get providerId
  let providerId = to.query.providerId;

  if (!providerId && to.query.state) {
    // If not found, check within the state parameter
    const stateParams = new URLSearchParams(to.query.state);
    providerId = stateParams.get('providerId');
  }

  return providerId;
}