<template>
  <transition name="slide-down" appear>
    <div class="popup-error_screen">
      <div class="popup_error_wrapper">
        <div
          class="popup_error_container"
          :class="{
            error: errorPopupType == 'error',
            warning: errorPopupType == 'warning',
          }"
        >
          <BaseIcon
            :icon="
              errorPopupType == 'warning'
                ? 'warning_dark-yellow'
                : 'warning_red'
            "
            size="32"
          />
          <div>
            <p class="bold">
              {{ errorMessage }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import BaseIcon from '../assets/BaseIcon.vue';

export default {
  name: 'PopupError',

  components: { BaseIcon },
  data() {
    return {
      hideErrorTimeout: null,
    };
  },
  computed: {
    errorMessage() {
      return (
        this.$store.state.error.errorPopupMessage ??
        'Something went wrong. Please try again or contact support.'
      );
    },
    errorPopupType() {
      return this.$store.state.error.errorPopupType ?? 'error';
    },
  },

  mounted() {
    this.$nextTick(() => {
      document.addEventListener('click', this.handleClickOutside);
      document.addEventListener('keydown', this.handleKeyDown);
    });

    this.hideErrorTimeout = setTimeout(() => {
      this.$store.dispatch('error/updatePopupErrorStatus', { status: false });
    }, 4000);
  },
  beforeUnmounted() {
    clearTimeout(this.hideErrorTimeout);
    document.removeEventListener('click', this.handleClickOutside);
    document.removeEventListener('keydown', this.handleKeyDown);
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch('error/updatePopupErrorStatus', { status: false });
    },
    handleKeyDown() {
      this.$store.dispatch('error/updatePopupErrorStatus', { status: false });
    },
  },
};
</script>

<style lang="scss" scoped>
.popup-error_screen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  width: 100%;
}

.popup_error_wrapper {
  display: flex;
  justify-content: center;
  display: flex;
  padding: 0 20px;
  margin: 0 auto;
}

.popup_error_container {
  width: 100%;
  height: auto !important;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 12px;
  width: 100%;

  margin-top: 24px;
  box-shadow: 0px 20px 40px -16px rgba(110, 120, 119, 0.16);

  &.error {
    background-color: #fef7f9;
  }

  &.warning {
    background-color: #fffaf1;
  }

  h6 {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 140% !important; /* 19.6px */
    letter-spacing: -0.28px !important;
  }
}

@media (min-width: 600px) {
  .popup_error_wrapper {
    padding: 0 32px;
  }
}

@media (min-width: 905px) {
  .popup_error_wrapper {
    padding: 0;
    width: 60%;
    margin-left: 20%;
  }
}
</style>
