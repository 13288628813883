<template>
  <BaseModal
    v-if="!isLoading"
    noCloseButton
    title="Before starting"
    class="terms-modal"
    :canBeClosed="false"
  >
    <div class="p-l-1">
      <p>
        By starting an application you agree to our
        <span class="clickable bold" @click="openLink('terms')">
          terms
        </span>
        and
        <span class="clickable bold" @click="openLink('policy')">
          privacy policy
        </span>
        .
      </p>
      <div class="m-t-2">
        <base-checkbox
          id="updatesAccepted"
          preventChange
          checkedByDefault
          @onInput="inputChanged"
        >
          Receive updates about similar Pillar products and services.
          <div class="popper-block">
            <Popper hover="true" :style="myStyles" placement='bottom-end'>
              <span class="clickable bold" data-cy="termsLink">
                Terms apply.
              </span>
              <template #content>
                We'll provide updates by email, text messages,
                social media and push notifications.
                <br />
                You can opt-out anytime.
                <br />
                See our Privacy Policy for more details.
              </template>
            </Popper>
          </div>
        </base-checkbox>
      </div>
    </div>
    <base-button-primary class="continueButton" @clicked="saveComms">
      Continue
    </base-button-primary>
  </BaseModal>
</template>

<script>
import BaseModal from './BaseModal.vue';
import BaseButtonPrimary from '../buttons/BaseButtonPrimary.vue';
import BaseCheckbox from '../inputs/BaseCheckbox.vue';
import Popper from 'vue3-popper';

export default {
    name: 'TermsAndConditionsModal',
    components: { BaseCheckbox, BaseButtonPrimary, BaseModal, Popper },

    data() {
        return {
            isLoading: true,
            consentAndComms: {
                marketing: true,
                privacyPolicy: true,
                termsOfUse: true,
            },
            myStyles: {
                '--popper-theme-background-color': '#333333',
                '--popper-theme-background-color-hover': '#333333',
                '--popper-theme-text-color': '#ffffff',
                '--popper-theme-border-width': '0px',
                '--popper-theme-border-style': 'solid',
                '--popper-theme-border-radius': '8px',
                '--popper-theme-padding': '16px',
                '--popper-theme-box-shadow':
                    '0 6px 30px -6px rgba(0, 0, 0, 0.25)',
            },
        };
    },

    mounted() {
        this.isLoading = false;
        this.$store.dispatch('navigation/stopLoading');
    },
    methods: {
        inputChanged(id, value) {
            if (id == 'updatesAccepted') {
                this.consentAndComms.marketing = value;
            }
        },

        async saveComms() {
          const applicationId = localStorage.getItem('applicationId');
            this.$store.dispatch(
                'navigation/buttonPrimaryInLoadingState',
                true
            );
            const data = { consentAndComms: this.consentAndComms };

            const updateApplicationReq = await this.$store.dispatch(
                'application/updateApplication',
                { data, applicationId }
            );

            if (!updateApplicationReq.status) {
                router.push('/error');
                return false;
            }

            this.$emit('termsAccepted');
        },

        openLink(doc) {
            let link = 'https://www.hellopillar.com/terms-of-use';
            if (doc == 'policy') {
                link = 'https://www.hellopillar.com/privacy-policy';
            }

            const webview = localStorage.getItem('webview') == 'true';

            if (webview) {
                window.OpenUrlInWebview.postMessage(link);
            } else {
                window.open(link, '_blank');
            }
        },
    },
};
</script>

<style>
.continueButton {
    margin-top: 24px;
}

.terms-modal {
    z-index: 100000;
}

.popper {
    margin-left: 12px !important;
}
</style>
