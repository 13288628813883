

let listOfBanks =
    [
        { "display_name": "AIB", "provider_id": "ob-aib", "logo_url": "https://truelayer-provider-assets.s3.amazonaws.com/global/logos/aib.svg" },
        { "display_name": "Barclays", "provider_id": "ob-barclays", "logo_url": "https://truelayer-provider-assets.s3.amazonaws.com/global/logos/barclays.svg" },
        { "display_name": "Bank of Ireland", "provider_id": "ob-boi-ie", "logo_url": "https://truelayer-provider-assets.s3.amazonaws.com/global/logos/boi.svg" },
        { "display_name": "Bank of Scotland", "provider_id": "ob-bos", "logo_url": "https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/bos.svg" },
        { "display_name": "Chase", "provider_id": "ob-chase", "logo_url": "https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/chase.svg" },
        { "display_name": "first direct", "provider_id": "ob-first-direct", "logo_url": "https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/first-direct.svg" },
        { "display_name": "Halifax", "provider_id": "ob-halifax", "logo_url": "https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/halifax.svg" },
        { "display_name": "HSBC", "provider_id": "ob-hsbc", "logo_url": "https://truelayer-provider-assets.s3.amazonaws.com/global/logos/hsbc.svg" },
        { "display_name": "Lloyds", "provider_id": "ob-lloyds", "logo_url": "https://truelayer-provider-assets.s3.amazonaws.com/global/logos/lloyds.svg" },
        { "display_name": "Monzo", "provider_id": "ob-monzo", "logo_url": "https://truelayer-provider-assets.s3.amazonaws.com/global/logos/monzo.svg" },
        { "display_name": "Nationwide", "provider_id": "ob-nationwide", "logo_url": "https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/nationwide.svg" },
        { "display_name": "NatWest", "provider_id": "ob-natwest", "logo_url": "https://truelayer-provider-assets.s3.amazonaws.com/global/logos/natwest.svg" },
        { "display_name": "Revolut", "provider_id": "ob-revolut", "logo_url": "https://truelayer-provider-assets.s3.amazonaws.com/global/logos/revolut.svg" },
        { "display_name": "Royal Bank of Scotland", "provider_id": "ob-rbs", "logo_url": "https://truelayer-provider-assets.s3.amazonaws.com/global/logos/rbs.svg" },
        { "display_name": "Santander", "provider_id": "ob-santander", "logo_url": "https://truelayer-provider-assets.s3.amazonaws.com/global/logos/santander.svg" },
        { "display_name": "Starling", "provider_id": "ob-starling", "logo_url": "https://truelayer-provider-assets.s3.amazonaws.com/global/logos/starling.svg" },
        { "display_name": "TSB", "provider_id": "ob-tsb", "logo_url": "https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/tsb.svg" },
        { "display_name": "Ulster Bank", "provider_id": "ob-ulster", "logo_url": "https://truelayer-provider-assets.s3.amazonaws.com/global/logos/ulster.svg" },
    ];

if (process.env.VUE_APP_ENV !== "production") {
    // Add the mock bank to the array
    listOfBanks.unshift({ "display_name": "Mock", "provider_id": "mock", "logo_url": "https://truelayer-provider-assets.s3.amazonaws.com/global/logos/mock.svg" });
}

export const LIST_OF_BANKS = listOfBanks;

export const POPULAR_BANKS = [
    { "display_name": "Barclays", "provider_id": "ob-barclays", "logo_url": "https://truelayer-provider-assets.s3.amazonaws.com/global/logos/barclays.svg" },
    { "display_name": "Lloyds", "provider_id": "ob-lloyds", "logo_url": "https://truelayer-provider-assets.s3.amazonaws.com/global/logos/lloyds.svg" },
    { "display_name": "Halifax", "provider_id": "ob-halifax", "logo_url": "https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/halifax.svg" },
    { "display_name": "NatWest", "provider_id": "ob-natwest", "logo_url": "https://truelayer-provider-assets.s3.amazonaws.com/global/logos/natwest.svg" },
    { "display_name": "Santander", "provider_id": "ob-santander", "logo_url": "https://truelayer-provider-assets.s3.amazonaws.com/global/logos/santander.svg" },
    { "display_name": "Nationwide", "provider_id": "ob-nationwide", "logo_url": "https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/nationwide.svg" },
    { "display_name": "Monzo", "provider_id": "ob-monzo", "logo_url": "https://truelayer-provider-assets.s3.amazonaws.com/global/logos/monzo.svg" },
    { "display_name": "HSBC", "provider_id": "ob-hsbc", "logo_url": "https://truelayer-provider-assets.s3.amazonaws.com/global/logos/hsbc.svg" },
    { "display_name": "TSB", "provider_id": "ob-tsb", "logo_url": "https://truelayer-provider-assets.s3.amazonaws.com/uk/logos/tsb.svg" },
];