<template>
  <div v-if="showProdBanner" class="prodBanner">
    <div>You are using production credentials!</div>
    <div class="close-button" @click="showProdBanner = false">
      <BaseIcon icon="close_white" :isButton="true" />
    </div>
  </div>
  <div
    v-if="!initialLoading"
    class="app-wrapper"
    :class="{ 'has-vertical-scroll': hasVerticalScroll }"
  >
    <transition name="fade">
      <GlobalLoader v-if="isLoading" :animation-bank="animationBank" />
    </transition>

    <TermsAndConditionsModal
      v-if="showTermsAndConditionsModal"
      @termsAccepted="showTermsAndConditionsModal = false"
    />
    <div
      class="app-container"
      :class="{ 'desktop-full-width': desktopBGHidden }"
    >
      <DesktopBackground v-if="!desktopBGHidden" />
      <div ref="appContentWrap" class="app-content-wrap">
        <div
          v-if="headerIsVisible"
          class="app-header"
          :style="{
            backgroundColor: 'transparent',
          }"
        >
          <div class="header-content">
            <AppBar />
          </div>
        </div>
        <div class="main-content" :style="mainContentStyles">
          <PopupError v-if="errorPopupVisible" />
          <router-view v-slot="{ Component }" :key="$route.fullPath">
            <component :is="Component" />
          </router-view>
        </div>
        <div
          class="footer"
          :class="{
            'with-shadow': hasVerticalScroll && footerHeight,
          }"
          :style="{
            backgroundColor:
              footerHeight == 0 ? 'transparent' : 'white',
            zIndex: footerHeight == 0 ? '-1' : '1',
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from './components/assets/BaseIcon.vue';
import { ref, computed, onMounted } from 'vue';
import { intercomLogout } from './controllers/intercom.js';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { useDeviceType } from '@/utils/useDeviceType.js';
import PopupError from './components/popups/PopupError.vue';
import GlobalLoader from './components/layout/GlobalLoader.vue';
import BaseImage from './components/assets/BaseImage.vue';
import AppBar from './components/appBar/AppBar.vue';
import DesktopBackground from './components/layout/DesktopBackground.vue';
import TermsAndConditionsModal from './components/modal/TermsAndConditionsModal.vue';
import Firestore from './utils/firestore.js';

export default {
    name: 'App',
    components: {
        AppBar,
        BaseImage,
        GlobalLoader,
        PopupError,
        DesktopBackground,
        TermsAndConditionsModal,
        BaseIcon,
    },

    setup() {
        const store = useStore();
        const router = useRouter();
        const route = useRoute();

        // Prevent any render until loader type set up
        const initialLoading = ref(true);
        const showTermsAndConditionsModal = ref(false);
        const animationBank = ref(null);
        const showProdBanner = ref(false);

        const isLoading = computed(() => {
            return store.state.navigation.isLoading;
        });

        const { isDesktop } = useDeviceType();

        const progressBarVisible = computed(() => {
            return store.state.navigation.progressBarVisible;
        });

        const headerIsVisible = computed(() => {
            return store.getters['navigation/headerIsVisible'];
        });

        const contentCentered = computed(() => {
            return store.state.navigation.contentCentered;
        });

        const hasVerticalScroll = computed(() => {
            return store.state.navigation.hasVerticalScroll;
        });

        const footerHeight = computed(() => {
            return store.state.navigation.footerHeight;
        });

        const errorPopupVisible = computed(() => {
            return store.state.error.errorPopupVisible;
        });

        const desktopBGHidden = computed(() => {
            return store.state.navigation.desktopBGHidden;
        });

        const activeScreen = computed(() => {
            return store.state.navigation.activeScreen;
        });

        const mainContentStyles = computed(() => {
            // Set the styles dynamically depending on content
            // Header:
            // If header is visible and content is not centered, add 56px margin
            // If content is centered and progress bar is visible, add margin
            // Else - set margin to 0
            const headerMargin =
                headerIsVisible.value && !contentCentered.value
                    ? '56px'
                    : headerIsVisible.value && progressBarVisible.value
                    ? '56px'
                    : '0px';

            // Footer:
            // If footer is visible and content is not centered, add {footerHeight} + 10px margin
            // If footer is not visible and content is centered, add 20px margin (to align with 20px padding top for content)

            const footerIsVisible = footerHeight.value > 0;
            let footerMargin =
                footerIsVisible && !contentCentered.value
                    ? `${footerHeight.value + 10}px`
                    : footerIsVisible &&
                      contentCentered.value &&
                      !isDesktop.value
                    ? `${footerHeight.value}px`
                    : contentCentered.value && !footerIsVisible
                    ? '20px'
                    : '0px';

            if (
                activeScreen.value == 'pre-contract' ||
                activeScreen.value == 'pcci' ||
                route.path == '/creditCardAgreement'
            ) {
                footerMargin = '200px';
            }

            return {
                marginTop: headerMargin,
                marginBottom: footerMargin,
            };
        });

        // onBeforeMount(() => {
        //   if (route.meta.requiresBankAnimation) {
        //     const providerId = extractProviderIdFromUrl();

        //     animationBank.value = providerId;
        //     store.dispatch('navigation/updateLoaderAnimationType', 'bank');
        //     initialLoading.value = false;
        //   }
        //   if (route.meta.requiresDEAnimation) {
        //     store.dispatch('navigation/updateLoaderAnimationType', 'de');
        //     initialLoading.value = false;
        //   }
        // });

        onMounted(async () => {
            // Intercom logout
            intercomLogout();
            // If the route is payment, don't proceed with checks
            if (route.path == '/truelayerpayment') {
                initialLoading.value = false;
                return false;
            }

            // Check if application has accepted terms and comms setup
            const applicationId = localStorage.getItem('applicationId');

            if (!applicationId) {
                initialLoading.value = false;
                return false;
            }

            const applicationData = await store.dispatch(
                'application/getApplicationRecord',
                { applicationId }
            );

            if (!applicationData.status) {
                store.dispatch('error/throwNewError', {
                    module: 'App.vue',
                    errorType: 'apiError',
                    comment: `getApplicationRecord() - bad response`,
                    context: {
                        applicationId,
                    },
                });
                router.push('/error');
                return;
            }

            const consentAndComms = applicationData.data?.consentAndComms;

            if (!consentAndComms) {
                showTermsAndConditionsModal.value = true;
                initialLoading.value = false;
            } else {
                const termsAccepted =
                    consentAndComms.privacyPolicy && consentAndComms.termsOfUse;
                if (!termsAccepted) {
                    showTermsAndConditionsModal.value = true;
                } else {
                    initialLoading.value = false;
                }
            }

            if (process.env.VUE_APP_ENV === 'github') {
                const envConfig = await Firestore.getDoc(
                    'Config',
                    'EnvironmentSwitch'
                );
                const useProd = envConfig.useProd;

                if (useProd) {
                    showProdBanner.value = true;
                }
            }
        });

        // Get providerId (bank) to show ob animation
        function extractProviderIdFromUrl() {
            // Directly use route.query to get providerId
            let providerId = route.query.providerId;

            if (!providerId && route.query.state) {
                // If not found, check within the state parameter
                const stateParams = new URLSearchParams(route.query.state);
                providerId = stateParams.get('providerId');
            }

            return providerId;
        }

        return {
            showTermsAndConditionsModal,
            initialLoading,
            animationBank,
            progressBarVisible,
            desktopBGHidden,
            isLoading,
            footerHeight,
            hasVerticalScroll,
            errorPopupVisible,
            contentCentered,
            mainContentStyles,
            headerIsVisible,
            showProdBanner,
        };
    },
};
</script>

<style lang="scss">
html,
body {
    height: 100%;
    margin: 0;
    max-width: 100% !important;
    overflow-x: hidden !important;
}

#app {
    height: 100%;
}

// Wraps GlobalLoader and .app-container
.app-wrapper {
    height: 100%;
}

// Wraps .desktop-only and .app-content-wrap
.app-container {
    height: 100%;
    display: flex;
}

.screen-main.full-width {
    max-width: 100% !important;
}

.screen-main.base-screen-content-centered {
    flex: none !important;
}

.desktop-full-width {
    @media (min-width: 1024px) {
        .app-header,
        .footer,
        .main-content {
            width: 100% !important;
        }
    }
}

.app-content-wrap {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;

    @media (min-width: 1024px) {
        justify-content: center;
    }
}

.app-header {
    width: 100%;
    height: $header-height;
    right: 0;
    top: 0;
    position: fixed;
    width: 100%;
    z-index: 0;

    @media (min-width: 1024px) {
        width: 50%;
    }

    .header-content {
        padding-left: 20px;
        padding-right: 20px;
        height: 100%;
        max-width: $max-content-width;
        margin: 0 auto;

        @media (min-width: 600px) {
            padding-left: 32px;
            padding-right: 32px;
        }
    }
}

.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    background-color: white;
    z-index: 100;

    @media (min-width: 1024px) {
        position: static;
        width: 50%;
        margin-left: auto;
    }

    &.with-shadow {
        box-shadow: 0px -20px 40px -8px rgba(110, 120, 119, 0.16);
    }
}

.main-content {
    flex: 1;
    display: flex;
    justify-content: center;
    width: 100%;
    overflow-x: hidden !important;
    margin-top: $header-height;

    @media (min-width: 1024px) {
        width: 50%;
        margin-left: auto;
    }
}

@media (min-width: 1024px) {
    .footer {
        position: static;
    }

    .app-content-wrap {
        justify-content: center;
    }

    .main-content {
        flex: none;
    }
}

.has-vertical-scroll {
    @media (min-width: 1024px) {
        .footer {
            position: fixed;
            right: 0;
        }

        .app-content-wrap {
            justify-content: flex-start;
        }

        .main-content {
            flex: 1;
        }
    }
}

.prodBanner {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    color: white;
    background-color: #de4141;
    display: flex;
    align-items: center;
    gap: 18px;
}
</style>
