import { sendRequest } from "../config/axios/helper";


export const userUrls = {
    GET_USER_RECORD: {
        url: '/user/read',
        type: 'post',
        reqParams: ['userId']
    },
    CHECK_USER_HAS_PIN: {
        url: '/user/check/pin',
        type: 'post',
        reqParams: ['userId']
    }

}



export const getUserRecord = async params => {
    return await sendRequest(userUrls.GET_USER_RECORD, params);
};


export const checkUserHasPin = async params => {
    return await sendRequest(userUrls.CHECK_USER_HAS_PIN, params);
};

