// products.js - Vuex module for managing product data
import { addBreadcrumb, captureException } from '@sentry/vue';
import { createApplication, getApplicationRecord, updateApplication } from "../controllers/application";
import { getUserRecord } from "../controllers/user";
import router from "../router";
import { LocalStorage } from '../utils/localStorage';
import { randomString } from '../utils/generateRandomString';
import { uploadBytesResumable, ref, getDownloadURL } from 'firebase/storage';
import { storage } from "../utils/firestore.js";

const state = {
    applicationData: null,
    userRecordData: null
};

const mutations = {
    SET_APPLICATION_DATA (state, applicationData) {
        state.applicationData = applicationData;
    },
    APPLICATION_DATA_UPDATED (state, payload) {
        state.applicationData = { ...state.applicationData, ...payload };
    },
    SET_USER_RECORD_DATA (state, payload) {
        state.userRecordData = { ...state.userRecordData, ...payload };
    },
};

const actions = {
    async getApplicationRecord ({ commit, state }, payload) {
        const { applicationId, forceUpdate = false } = payload;

        addBreadcrumb({
            category: 'application',
            message: 'sending getApplicationRecord() request started...',
            data: {
                payload
            },
            level: 'info'
        });

        // If forceUpdate is false and applicationData is already in the store, return it
        if (!forceUpdate && state.applicationData) {
            addBreadcrumb({
                category: 'application',
                message: 'returning applicationData from the store',
                data: {
                    payload
                },
                level: 'info'
            });
            return {
                status: true,
                data: state.applicationData
            };
        }

        // Otherwise, make the API call

        const applicationRecordReq = await getApplicationRecord({ applicationId });

        if (applicationRecordReq.status) {
            const applicationData = applicationRecordReq.data;
            commit('SET_APPLICATION_DATA', applicationData);
        }

        return applicationRecordReq;
    },
    async updateApplicationStore ({ commit, state }, payload) {
        const mergedObj = {
            ...state.applicationData, ...payload
        };
        // let data = { ...state.applicationData, ...payload };
        commit('APPLICATION_DATA_UPDATED', mergedObj);

    },
    updateApplicationData ({ commit }, payload) {
        commit('APPLICATION_DATA_UPDATED', payload);
    },
    async createApplication ({ commit, dispatch }, applicationData) {

        const createApplicationReq = await createApplication(applicationData);

        if (!createApplicationReq.status) {

            if (createApplicationReq.statusCode == 403) {
                router.push('/paused');
                return createApplicationReq;
            }

            // dispatch('error/updatePopupErrorStatus', {
            //     status: true,
            //     message: createApplicationReq.friendlyMessage,
            // });
        }

        const applicationId = createApplicationReq.data;

        if (applicationId) {
            LocalStorage.set({ applicationId });
        }
        commit('APPLICATION_DATA_UPDATED', applicationData);
        return createApplicationReq;

    },
    async updateApplication ({ commit, dispatch }, payload) {
        const applicationId = localStorage.getItem('applicationId');

        const body = {
            applicationId
        };

        if (payload?.data) {
            body.data = payload.data;
        }

        if (payload?.step) {
            body.stepToPassed = payload.step;
        }
        const updateApplicationReq = await updateApplication(body);

        if (!updateApplicationReq.status) {
            dispatch('error/updatePopupErrorStatus', {
                status: true,
                message: updateApplicationReq.friendlyMessage,
            });
        }

        commit('APPLICATION_DATA_UPDATED', payload.data);
        return updateApplicationReq;

    },
    async getUserRecord ({ commit, state }, payload) {
        const { userId, forceUpdate = false } = payload;

        // If forceUpdate is false and userRecordData is already in the store, return it
        if (!forceUpdate && state.userRecordData) {
            return {
                status: true,
                data: state.userRecordData
            };
        }

        // Otherwise, make the API call
        const userRecordReq = await getUserRecord({ userId });

        if (userRecordReq.status) {
            const userRecordData = userRecordReq.data;
            commit('SET_USER_RECORD_DATA', userRecordData);
        }

        return userRecordReq;
    },
   
    uploadFileToStorage({ commit, dispatch }, { eventData, docType, userId }) {
        return new Promise((resolve, reject) => {
          const file = eventData.target.files[0];
  
          if (!file) {
            reject(new Error('No file selected'));
            return;
          }
  
          const extension = file.name.split('.').pop();
          const uuid = randomString(20);
          const fileName = `${docType}-${uuid}.${extension}`;
  
          const storageRef = ref(storage, `users/${userId}/documents/${docType}/${fileName}`);
          const uploadTask = uploadBytesResumable(storageRef, file);
  
          uploadTask.on(
            'state_changed',
            () => {
              // Progress handling (optional)
            },
            (error) => {
              captureException(error);
              reject(error);
            },
            async () => {
              try {
                const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
                resolve({
                  status: true,
                  data: {
                    downloadUrl,
                    fileName,
                    path: `users/${userId}/documents/${docType}/${fileName}`,
                    type: docType,
                  }
                });
              } catch (error) {
                reject(error);
              }
            }
          );
        });
      }
};

const getters = {
    applicationData: (state) => state.applicationData
};

export default {
    namespaced: true, // Namespaced module
    state,
    mutations,
    actions,
    getters,
};
