export const countryAddressFormInputs = {
    IN: [
        {
            id: 'buildingNameIN',
            value: '',
            type: 'text',
            label: 'Flat / Apartment Number',
            validationRequired: false,
        },
        {
            id: 'streetNameIN',
            value: '',
            type: 'text',
            label: 'Street Address*',
            validationRequired: true,
        },
        {
            id: 'localityIN',
            value: '',
            type: 'text',
            label: 'Locality/Area*',
            validationRequired: true,
        },
        {
            id: 'cityIN',
            value: '',
            type: 'text',
            label: 'City/Town/Village*',
            validationRequired: true,
        },
        {
            id: 'stateIN',
            value: '',
            type: 'text',
            label: 'State*',
            validationRequired: true,
        },
        {
            id: 'pinCodeIN',
            value: '',
            type: 'text',
            label: 'PIN Code*',
            validationRequired: true,
        },
    ],
    US:
        [
            {
                id: 'buildingNameUS',
                value: '',
                type: 'text',
                label: 'Flat / Apartment Number',
                validationRequired: false,
            },
            {
                id: 'streetNumberUS',
                value: '',
                type: 'text',
                label: 'Street Number*',
                validationRequired: true,
            },
            {
                id: 'streetNameUS',
                value: '',
                type: 'text',
                label: 'Street Name*',
                validationRequired: true,
            },
            {
                id: 'cityUS',
                value: '',
                type: 'text',
                label: 'City*',
                validationRequired: true,
            },
            {
                id: 'stateUS',
                value: '',
                type: 'text',
                label: 'State*',
                validationRequired: true,
            },
            {
                id: 'zipCodeUS',
                value: '',
                type: 'text',
                label: 'ZIP Code*',
                validationRequired: true,
            },
        ],
    AU: [
        {
            id: 'unitNumberAU',
            value: '',
            type: 'text',
            label: 'Unit Number',
            validationRequired: false,
        },
        {
            id: 'streetNumberAU',
            value: '',
            type: 'text',
            label: 'Street Number*',
            validationRequired: true,
        },
        {
            id: 'streetNameAU',
            value: '',
            type: 'text',
            label: 'Street Name*',
            validationRequired: true,
        },
        {
            id: 'suburbAU',
            value: '',
            type: 'text',
            label: 'Suburb*',
            validationRequired: true,
        },
        {
            id: 'stateAU',
            value: '',
            type: 'text',
            label: 'State*',
            validationRequired: true,
        },
        {
            id: 'postCodeAU',
            value: '',
            type: 'text',
            label: 'Postcode*',
            validationRequired: true,
        },
    ]
};
