//** INITIALIZATION */

import { initializeApp } from 'firebase/app';
import { getFirestore, connectFirestoreEmulator} from "firebase/firestore";
import { firebaseConfig } from '../firebaseConfig'; // Ensure the path is correct
import { getStorage, connectStorageEmulator } from 'firebase/storage';

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);



// Initialize Storage
const storage = getStorage(app);
if (process.env.VUE_APP_ENV == 'local') {
    connectStorageEmulator(storage, 'localhost', 9199);
}


if (process.env.VUE_APP_ENV == 'local') {
    connectFirestoreEmulator(db, 'localhost', 9999);
}

import {
    collection,
    query,
    getDocs,
    getDoc,
    doc,
    onSnapshot,
    orderBy as orderByFirestore,
    limit as limitFirestore
  } from 'firebase/firestore';
  


//** HELPER */

  /**
 * Firestore utility for interacting with Firebase Firestore database.
 * @namespace Firestore
 */

  const Firestore = {
    db: db,

    /**
     * 
     * @description - Fetches multiple documents from the specified collection.
     * @param {string} collectionPath - The path to the collection in Firestore.
     * @param {Object} [options={}] - Optional parameters for querying.
     * @param {string} [options.orderBy] - Field by which to order the results.
     * @param {'asc'|'desc'} [options.orderByDirection='desc'] - Order direction for results.
     * @param {number} [options.limit] - Limit the number of results returned.
     * 
     * @returns {Promise<Array<Object>>} An array of document data with each document including its ID as the 'docId' property.
     * 
     * @example
     * const users = await Firestore.getDocs('Users', { orderBy: 'createdAtTimestamp', limit: 10 });
     */

    async getDocs(collectionPath, { orderByField, orderByDirection = 'desc', limitCount } = {}) {
      try {
        let constraints = [];
  
        if (orderByField) {
          constraints.push(orderByFirestore(orderByField, orderByDirection));
        }
  
        if (limitCount) {
          constraints.push(limitFirestore(limitCount));
        }
  
        const q = query(collection(this.db, collectionPath), ...constraints);
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => ({ ...doc.data(), docId: doc.id }));
      } catch (error) {
        console.error("Error fetching documents:", error);
        throw error;
      }
    },
  
     /**
     * 
     * @description - Fetches a single document from the specified collection by its document ID.
     * @param {string} collectionName - The name of the collection in Firestore.
     * @param {string} docId - The ID of the document to fetch.
     * 
     * @returns {Promise<Object|null>}The document data with its ID as the 'docId' property, or null if not found.
     * 
     * @example
     * const user = await Firestore.getDoc('Users', 'docId12345');
     */

    async getDoc(collectionName, docId) {
      try {
        const docRef = doc(this.db, collectionName, docId);
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists()) {
            return { ...docSnapshot.data(), docId: docSnapshot.id };
        } else {
          return null;
        }
      } catch (error) {
        console.error("Error fetching document:", error);
        throw error;
      }
    },


    /**
     * 
     * @description - Subscribes to a document and invokes the provided callback when the document changes.
     * @param {string} collectionName - The name of the collection in Firestore.
     * @param {string} docId - The ID of the document to subscribe to.
     * @param {function} callback - The callback to invoke when the document changes.
     * 
     * @returns {function} An unsubscribe function to stop listening for changes.
     * 
     * @example
     * const unsubscribe = Firestore.subscribeToDoc('Users', 'docId12345', (doc) => console.log(doc));
     * // Later...
     * unsubscribe();
     */
    async subscribeToDoc(collectionName, docId, callback) {
        try {
            const unsubscribe = onSnapshot(
                doc(this.db, collectionName, docId),
                (docSnapshot) => {
                    if (docSnapshot.exists()) {
                        callback({ ...docSnapshot.data(), docId: docSnapshot.id });
                    } else {
                        callback(null);
                    }
                }
            );
    
            return unsubscribe; // This allows the caller to unsubscribe later.
    
        } catch (error) {
            console.error("Error in subscribeToDoc:", error);
            throw error;
        }
    }
    
    
    
    
  }
  
  export  {Firestore, storage};
  