import { withScope, captureException } from '@sentry/vue';
class WebOnboardingError extends Error {
    constructor(message) {
        super(message);
        this.name = 'Web-Onboarding Error';
    }
}

const state = {
    errorPopupVisible: false,

    errorScreenActive: false,
    errorSnackbarActive: false,

    errorPopupMessage: null,

    // New
    errorType: null,
    errorDetails: null,
    errorMessage: null,
    errorTitle: null,
    errorPopupType: null,
};

const mutations = {
    UPDATE_ERROR_POPUP_STATE(state, payload) {
        state.errorPopupVisible = payload;
    },
    SET_ERROR_SCREEN_TYPE(state, screen) {
        state.errorType = screen;
    },
    SET_ERROR_DETAILS(state, details) {
        state.errorDetails = details;
    },
    SET_ERROR_MESSAGE(state, message) {
        state.errorMessage = message;
    },
    SET_ERROR_TITLE(state, payload) {
        state.errorTitle = payload
    },

    ERROR_SCREEN_STATE_UPDATE(state, payload) {
        state.errorScreenActive = payload;
    },

    ERROR_SNACKBAR_STATE_UPDATE(state, payload) {
        state.errorSnackbarActive = payload;
    },

    ERROR_MESSAGE_UPDATE(state, payload) {
        state.errorMessage = payload;
    },
    UPDATE_ERROR_IMAGE(state, payload) {
        state.errorImage = payload
    },

    UPDATE_ERROR_POPUP_MESSAGE(state, payload) {
        state.errorPopupMessage = payload;
    },

    UPDATE_ERROR_TITLE(state, payload) {
        state.errorTitle = payload
    },
    SET_ERROR_POPUP_TYPE(state, payload) {
        state.errorPopupType = payload
    }
};

const actions = {
    updatePopupErrorStatus({ commit }, payload) {
        const status = payload.status;
        const type = payload.type;

        if (status !== undefined) {
            commit('UPDATE_ERROR_POPUP_MESSAGE', payload.message ?? null);
            commit('UPDATE_ERROR_POPUP_STATE', status);
            commit('SET_ERROR_POPUP_TYPE', type ?? null);
        }

    },
    throwNewError({ commit }, errorObj) {
        const { errorType, message, title, module, comment, context } = errorObj;

        if (errorType) {
            commit('SET_ERROR_SCREEN_TYPE', errorType);
        }
        if (module || comment || context) {
            commit('SET_ERROR_DETAILS', { module, comment, context })
        }
        if (message) {
            commit('SET_ERROR_MESSAGE', message);
        }
        if (title) {
            commit('SET_ERROR_TITLE', title);
        }

        withScope(scope => {

            scope.setTag("title", "Web-Onboarding Error");

            // Add specific error metadata
            scope.setTag("module", module);
            scope.setTag("errorType", errorType);
            scope.setExtra("comment", comment);
            scope.setExtra("context", {
                context,
                module,
                friendlyErrorMessage: message
            });

            const header = `Error in ${module}: ${comment}`;
            // Capture the error with the custom exception class
            captureException(new WebOnboardingError(header));
        });

    },
    setError({ commit }, message) {
        commit('SET_ERROR_MESSAGE', message);
    },
    clearError({ commit }) {
        commit('SET_ERROR_MESSAGE', null);
    },
    showErrorScreen({ commit, dispatch }) {
        commit('ERROR_SCREEN_STATE_UPDATE', true);
        dispatch('routeLoadingFinish');
    },
    hideErrorScreen({ commit, dispatch }) {
        commit('ERROR_SCREEN_STATE_UPDATE', false);
        dispatch('setErrorMessage', null);
    },

    setErrorMessage({ commit }, payload) {
        commit('ERROR_MESSAGE_UPDATE', payload);
    },
    hideErrorSnackbar({ commit, dispatch }) {

        commit('ERROR_SNACKBAR_STATE_UPDATE', false);
        dispatch('setErrorMessage', null);
    },
    setErrorTitle({ commit }, payload) {
        commit('UPDATE_ERROR_TITLE', payload)
    },
    setErrorImage({ commit }, payload) {
        commit('UPDATE_ERROR_IMAGE', payload)
    },
};

const getters = {
    errorMessage: (state) => state.errorMessage,
    errorScreenActive: (state) => state.errorScreenActive,
    errorSnackbarActive: (state) => state.errorSnackbarActive,
    errorTitle: (state) => state.errorTitle,
    errorImage: (state) => state.errorImage,
    errorType: (state) => state.errorType,
    errorDetails: (state) => state.errorDetails,
};

export default {
    namespaced: true, // Namespaced module
    state,
    mutations,
    actions,
    getters,
};
